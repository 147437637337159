import { useQuery } from "@apollo/client";
import QUOTE_ALERTS from "queries/QnB/getQuoteAlerts";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AlertBanner } from "react-lib";
import { getArray } from "utils/get";
import { messages } from "./messages";

const QnBAlerts = () => {
  const [alertToShow, setAlertToShow] = useState();
  const intl = useIntl();
  const { data } = useQuery(QUOTE_ALERTS, {
    variables: { locale: intl.locale },
    skip: !intl.locale,
  });
  useEffect(() => {
    const alerts = getArray(data, "getQuoteAlerts");
    const filteredAlerts = alerts.filter(
      (item) => localStorage.getItem(item?.id) !== "confirmed"
    );
    if (filteredAlerts.length) setAlertToShow(filteredAlerts[0]);
  }, [data, alertToShow, setAlertToShow]);
  return (
    !!alertToShow && (
      <AlertBanner
        description={alertToShow?.text}
        title={alertToShow?.title}
        linkToFollow={alertToShow?.url}
        okButtonText={intl.formatMessage(messages.okButton)}
        learnMoreText={intl.formatMessage(messages.learnMoreLink)}
        onOkClick={() => {
          if (alertToShow?.id) {
            localStorage.setItem(alertToShow?.id, "confirmed");
            setTimeout(() => {
              setAlertToShow(undefined);
            }, 200);
          }
        }}
      />
    )
  );
};

export default QnBAlerts;
