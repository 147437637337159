import getConfigClient from "constants/getConfigClient";
import { getActualLanguage } from "containers/Translations/Translations.helpers";
import getKeycloakUrl from "helpers/getKeycloakUrl";
import cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import get from "utils/get";
import { refreshToken } from "utils/interceptor";

const getKeycloakRedirectUrl = (returnUrl) => {
  const url = new URL(getConfigClient("KEYCLOAK_REDIRECT_URI"));
  if (returnUrl) {
    url.searchParams.set("ret", returnUrl);
  }
  return url.toString();
};

export const getKeycloakLoginUrl = (returnUrl = null) => {
  const url = getKeycloakUrl(getKeycloakRedirectUrl(returnUrl));
  url.searchParams.set("ui_locales", getActualLanguage().replace("_", "-"));
  return url.toString();
};

export const getKeycloakLogoutUrl = (tokenId = null) => {
  const url = new URL("logout", getConfigClient("KEYCLOAK_URL"));
  url.searchParams.set("redirect_uri", getKeycloakRedirectUrl(null));
  url.searchParams.set("client_id", getConfigClient("KEYCLOAK_CLIENT_ID"));

  if (tokenId) {
    url.searchParams.set("id_token_hint", tokenId);
  }

  return url.toString();
};

export const setCookie = (key, value, cookieConfig) => {
  cookie.set(key, value, {
    path: "/",
    ...cookieConfig,
  });
};

export const getCookie = (key) => {
  return cookie.get(key);
};

export const removeCookie = (key) => {
  cookie.remove(key, {
    expires: 1,
    path: "/",
  });
};

export const getTokenExpiryTimestamp = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const getPropertyFromToken = (token, property) => {
  try {
    const decodedToken = jwtDecode(token);
    return get(decodedToken, property);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const hasValidAuthToken = () => {
  const authTokenExpiry = localStorage.getItem("access_token_expiry");
  const authToken = localStorage.getItem("access_token");
  if (!authTokenExpiry || !authToken) return false;
  const currentTimestamp = Math.floor(new Date() / 1000);
  return authTokenExpiry > currentTimestamp;
};

export const hasValidRefreshToken = () => {
  const refreshTokenExpiry = localStorage.getItem("refresh_token_expiry");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshTokenExpiry || !refreshToken) return false;
  const currentTimestamp = Math.floor(new Date() / 1000);
  return refreshTokenExpiry > currentTimestamp;
};

export const tokenExpiringSoon = () => {
  const authTokenExpiry = localStorage.getItem("access_token_expiry");
  const currentTimestamp = Math.floor(new Date() / 1000);
  const diff = authTokenExpiry - currentTimestamp;
  return diff > 0 && diff < 20;
};

export const isAccessTokenExpired = () => {
  try {
    const authToken = localStorage.getItem("access_token");
    const tokenExpiry = getTokenExpiryTimestamp(authToken);
    const currentTimestamp = Math.floor(new Date() / 1000);
    return tokenExpiry < currentTimestamp;
  } catch {
    return false;
  }
};

export const refreshTokenIfExpiringSoon = () => {
  try {
    if (tokenExpiringSoon() && window.location.pathname !== "/login") {
      if (!window.refreshingTokenFromKeyCloak && hasValidRefreshToken()) {
        window.refreshingTokenFromKeyCloak = true;
        refreshToken().then(() => {
          window.refreshingTokenFromKeyCloak = false;
        });
      }
    }
  } catch (e) {
    //
  }
};

export const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const tokenExpiry = getTokenExpiryTimestamp(token);
    const currentTimestamp = Math.floor(new Date() / 1000);
    return tokenExpiry < currentTimestamp;
  } catch (e) {
    return true;
  }
};

export const transformRequest = (jsonData = {}) =>
  Object.entries(jsonData)
    .map(
      (x) =>
        `${encodeURIComponent(x[0])}=${
          x[1] !== undefined ? encodeURIComponent(x[1]?.toString()) : ""
        }`
    )
    .join("&");
