import ActionText from "components/atoms/ActionText";
import StrongText from "components/atoms/StrongText";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const ContainerList = ({
  containers,
  currentContainer,
  seeAllAfter,
  seeAllActivateForCount,
}) => {
  const [seeAll, setSeeAll] = useState(false);
  const showAllDisabled = containers.length < seeAllActivateForCount;
  const slicedList =
    showAllDisabled || seeAll
      ? containers.slice()
      : containers.slice(0, seeAllAfter);
  return (
    <div>
      {slicedList.map((container, i) => {
        return (
          <Fragment key={container}>
            {i !== 0 && <span>, </span>}
            {container === currentContainer ? (
              <StrongText>{container}</StrongText>
            ) : (
              <Link to={container}>{container}</Link>
            )}
          </Fragment>
        );
      })}
      {showAllDisabled ? null : seeAll ? (
        <ActionText
          onClick={() => {
            setSeeAll(false);
          }}
        >
          see less
        </ActionText>
      ) : (
        <ActionText
          onClick={() => {
            setSeeAll(true);
          }}
        >
          see all ({containers.length})
        </ActionText>
      )}
    </div>
  );
};
ContainerList.defaultProps = {
  containers: [],
  seeAllAfter: 4,
  seeAllActivateForCount: 6,
};
ContainerList.propTypes = {
  containers: PropTypes.array,
  currentContainer: PropTypes.string,
  seeAllAfter: PropTypes.number,
  seeAllActivateForCount: PropTypes.number,
};

export default ContainerList;
