import SVGWrapperBase from "components/atoms/SVGWrapper";
import { colors } from "react-lib";
import styled from "styled-components";

export const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SVGWrapper = styled(SVGWrapperBase).attrs({
  fill: colors.gray60,
  hoverFill: colors.gray60,
  width: "1.25rem",
  height: "1.25rem",
})`
  margin-left: 0.75rem;
`;

export const Wrapper = styled.div`
  padding-top: ${(p) => (p.print ? 0 : "3.125rem")};
`;

export const SingleDetailWrapper = styled.div`
  margin-bottom: 1.25rem;

  @media (min-width: 64.0125rem) {
    margin-bottom: 1.875rem;
  }
`;

export const StrongText = styled.span`
  line-height: 1.5;
  font-weight: bold;
  font-size: 1rem;
`;
