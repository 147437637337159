import gql from "graphql-tag";

const loadSingleShipmentWithoutMapDetails = gql`
  query ShipmentDetailsQuery($shipmentId: String!, $demo: Boolean!) {
    getShipmentDetails(shipmentId: $shipmentId, demo: $demo) {
      statusCode
      errorCode
      errorMessage
      data {
        delayInfo {
          expectedDescription
          expectedTimestamp
          realDescription
          realTimestamp
        }
        delayDaysFloat
        shipmentId
        houseBill
        isFavorite
        delayStatus
        transportMode
        origin {
          name
          countryCode
        }
        destination {
          name
          countryCode
        }
        transitStatus
        lastEvent {
          location
          name
          code
        }
        arrivalDate
        transportUnitDetails {
          unitId
          pathPoints {
            typeCode
            location {
              name
              countryName
              unLoCode
              countryCode
            }
            events {
              code
              reasonCode
              planned
              estimated
              actual
              delayDaysFloat
              signee
              flags
            }
            locationType
          }
        }
        carrierNames
        shipperName
        consigneeName
        customerName
        references
        masterBills
        flightNumbers
        vesselNames
        containerNumbers
        containerSizes
        goodsDescriptions
        actualWeight
        volume
        volumeUnit
        weightUnit
        chargeableWeight
        pieces
        actualArrival
        estimatedArrival
        actualDeparture
        actualPickup
        documentHandover
        cdzFlag
        shipmentProgress
        incoTerms {
          name
        }
        serviceIncidents {
          exceptionReason
          freeText
          responsibleParty
          timestamp
        }
        shipperStreet
        shipperCity
        shipperZip
        shipperCountry
        consigneeStreet
        consigneeCity
        consigneeZip
        consigneeCountry
        loadingMeter
        featureNames
        productName
        productBu
        co2eTotal
        mainLegEfficiency
        sourceSystemName
      }
    }
  }
`;
export default loadSingleShipmentWithoutMapDetails;
