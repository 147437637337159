import gql from "graphql-tag";

const GET_AEM_BY_LANGUAGE_AND_COUNTRY = gql`
  query GetAemByLanguageAndCountry($params: AEMParams!) {
    getAemByLanguageAndCountry(params: $params) {
      url_contact_us
      url_contact_us_unregistered
      url_legal_notice
      url_privacy_notice
      url_terms_of_use
      code_aem
    }
  }
`;

export default GET_AEM_BY_LANGUAGE_AND_COUNTRY;
