export enum UserRoles {
  PURCHASE_ORDERS = "myDHLi_PO_PO",
  PURCHASE_ORDER_LINES = "myDHLi_POLine_POLine",
  READ_ONLY = "myDHLi_ReadOnly_ReadOnly",
  VIRTUAL_ASSISTANT = "myDHLi_AIDA_AIDA",
  DOCUMENTS = "myDHLi_Documents_Documents",
  DOCUMENTS_UPLOAD = "myDHLi_Documents_Upload",
  // TODO: Remove after going live with new shipments
  TRACK_2 = "myDHLi_Track2_Track2",
}
