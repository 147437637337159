import globalDateMessages from "constants/dateMessages";
import Prefixes from "constants/shipments";
import timeDiffToTZAbbr from "constants/timeDiffToTZAbbr";
import { ReactComponent as InfoIcon } from "assets/icons/myDHLi/icon-user-interface-information-outline.svg";
import FreightIcon from "components/atoms/Icons/FreightIcon";
import SVGWrapper from "components/atoms/SVGWrapper";
import ShipmentFavoriteButton from "components/molecules/ShipmentFavoriteButton/ShipmentFavoriteButton";
import { shipmentDelayStatuses } from "data/shipmentDetailTypes";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";
import { messages as routeMessages } from "../../Route/Route.messages";
import { messages as shipmentRowMessages } from "../messages";
import { NumberOfLevelsInShipmentProgressBar } from "./constants";
import { dispatchFollowUnfollowEventForWidgetType } from "./getCellContentFunctions";

const OldArrivalDateText = styled.span`
  font-size: ${getRem(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.gray60};
  text-decoration: line-through;
`;
const NewArrivalDateText = styled.span`
  padding-left: ${(p) => (p.isDelayed ? getRem(4) : 0)};
`;

const PopupWrapper = styled.span`
  background-color: #323232;
  font-family: Delivery;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0.375rem 0.5rem;
`;

const DelayStatusComp = ({ item, isHeader }) => {
  const intl = useIntl();
  const [hovered, setHovered] = useState(false);
  const delayDescription = get(item, "delayInfo.expectedDescription");
  return (
    <>
      {!item?.delayStatus
        ? ""
        : !routeMessages[`shipmentDelayStatus_${item.delayStatus}`]
        ? item.delayStatus
        : `${intl.formatMessage(
            routeMessages[`shipmentDelayStatus_${item.delayStatus}`],
            {
              days: get(item, "delayDaysFloat", 0) || 0,
            }
          )}`}
      {item?.delayStatus === shipmentDelayStatuses.DELAYED &&
        isHeader &&
        item?.delayInfo?.expectedDescription && (
          <>
            <SVGWrapper
              width="1.5rem"
              height="1.5rem"
              showPointer
              onMouseOver={() => setHovered(true)}
              onMouseOut={() => setHovered(false)}
              style={{ marginLeft: getRem(7), marginRight: getRem(10) }}
              fill="#000"
              fillOpacity=".6"
            >
              <InfoIcon />
            </SVGWrapper>
            {hovered && (
              <PopupWrapper>{`${
                delayDescription
                  ? intl.formatMessage({
                      id: Prefixes.milestone + delayDescription,
                      defaultMessage: delayDescription,
                    })
                  : "--"
              }: ${timeDiffToTZAbbr(
                moment
                  .parseZone(get(item, "delayInfo.expectedTimestamp", ""))
                  .format(intl.formatMessage(globalDateMessages.longDatetime))
              )}`}</PopupWrapper>
            )}
          </>
        )}
    </>
  );
};

const getCellContentSmall = ({
  key,
  item,
  translate = () => {},
  isHeader = false,
  intl,
  rowIndex,
  type,
}) => {
  switch (key) {
    case "Follow":
      return (
        <ShipmentFavoriteButton
          shipment={item}
          dispatchInteractionEvent={(isFavorite) =>
            dispatchFollowUnfollowEventForWidgetType(type, isFavorite, rowIndex)
          }
        />
      );
    case "Mode":
      return (
        <SVGWrapper width={getRem(40)} height={getRem(40)}>
          <FreightIcon type={item.transportMode} isCDZ={!!item.cdzFlag} />
        </SVGWrapper>
      );
    case "Origin":
      return (
        <span>{`${get(item, "origin.name", "Not Found")}${
          get(item, "origin.countryCode")
            ? `, ${get(item, "origin.countryCode")}`
            : ""
        }`}</span>
      );

    case "Destination":
      return (
        <span>{`${get(item, "destination.name", "Not Found")}${
          get(item, "destination.countryCode")
            ? `, ${get(item, "destination.countryCode")}`
            : ""
        }`}</span>
      );
    case "HouseBill":
      return <span>{item.houseBill}</span>;
    case "DelayStatus":
      return <DelayStatusComp item={item} isHeader={isHeader} />;
    case "Arrival": {
      const delayDays = get(item, "deliveryEvent.delayDaysFloat", 0);
      const hasDelayDays = delayDays > 0;
      const arrivalDate = get(item, "arrivalDate");
      if (!hasDelayDays) {
        const arrivalDateText = arrivalDate
          ? moment
              .parseZone(arrivalDate)
              .format(intl.formatMessage(globalDateMessages.veryShortDate))
          : translate(shipmentRowMessages.timeNotAvailable);
        return <NewArrivalDateText>{arrivalDateText}</NewArrivalDateText>;
      } else {
        const estimatedDate = get(item, "deliveryEvent.estimated", 0);
        const plannedDate = get(item, "deliveryEvent.planned", 0);
        const estimatedDateText = estimatedDate
          ? moment
              .parseZone(estimatedDate)
              .format(intl.formatMessage(globalDateMessages.veryShortDate))
          : translate(shipmentRowMessages.timeNotAvailable);
        const plannedDateText = plannedDate
          ? moment
              .parseZone(plannedDate)
              .format(intl.formatMessage(globalDateMessages.veryShortDate))
          : translate(shipmentRowMessages.timeNotAvailable);
        return (
          <>
            <OldArrivalDateText>{plannedDateText}</OldArrivalDateText>
            <NewArrivalDateText isDelayed={hasDelayDays}>
              {estimatedDateText}
            </NewArrivalDateText>
          </>
        );
      }
    }
    case "Arrival_details": {
      const dateToDisplay =
        get(item, "delayInfo.realTimestamp") ||
        get(item, "delayInfo.expectedTimestamp") ||
        get(item, "actualArrival") ||
        get(item, "estimatedArrival") ||
        get(item, "arrivalDate");
      return dateToDisplay
        ? `${timeDiffToTZAbbr(
            moment
              .parseZone(dateToDisplay)
              .format(intl.formatMessage(globalDateMessages.longDatetime)) // Todo: format HH:mm Z when we decide to enable timezone
          )}`
        : "--";
    }
    case "Progress":
      return Math.floor(
        get(item, "shipmentProgress", 0) /
          (100 / NumberOfLevelsInShipmentProgressBar)
      );
    case "LastUpdate": {
      const lastEventName = item?.lastEvent?.code
        ? translate({
            id: Prefixes.milestone + item?.lastEvent?.code,
            defaultMessage: item?.lastEvent?.code,
          })
        : "--";
      return get(item, "lastEvent")
        ? `${lastEventName}, ${get(item, "lastEvent.location", "--")}`
        : "--";
    }
    case "EstimatedCustomerDelivery":
      return get(item, "estimatedCustomerDelivery")
        ? `${moment
            .parseZone(get(item, "estimatedCustomerDelivery"))
            .format(intl.formatMessage(globalDateMessages.veryShortDate))}${
            get(item, "delayDaysFloat", 0) > 0
              ? ` (+${get(item, "delayDaysFloat")}d)`
              : ""
          }`
        : "--";
    default:
      return "...";
  }
};

export default getCellContentSmall;
