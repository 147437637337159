import SVGWrapper from "components/atoms/SVGWrapper";
import styled, { css } from "styled-components";
import { getRem, getRemMedia, SCREEN_SIZES } from "utils/css";

export const ArrivalDetails = styled.div`
  font-size: ${getRem(16)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-bottom: ${getRem(16)};
  color: ${(p) => p.theme.colors.black};
`;

export const HouseBillValue = styled.span`
  font-size: ${getRem(16)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${(p) => p.theme.colors.black};
`;

export const TopSectionWrapper = styled.div`
  padding: 0;
  ${(p) =>
    p.inMapView || p.print
      ? null
      : css`
          @media (min-width: ${getRemMedia(768.2)}) {
            padding: 0 ${getRem(60)};
          }
        `}
  @media print {
    padding: 0 !important;
  }
`;

export const CheckMarkIconWrapper = styled(SVGWrapper).attrs((p) => ({
  margin: ` 0 ${getRem(10)} 0 0`,
  fill: p.delayed ? "black" : p.theme.colors.brightGreen,
  hoverFill: p.delayed ? "black" : p.theme.colors.brightGreen,
}))``;

export const ProgressStatusText = styled.div`
  font-size: ${getRem(16)};
  line-height: 1.25;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(p) =>
    p.isCompleted && !p.delayed
      ? p.theme.colors.brightGreen
      : p.theme.colors.black};
  display: flex;
  align-items: center;
`;

export const ServiceIncidentWrapper = styled.div`
  display: flex;
  margin: ${(p) => getRem(p.index === 0 ? 23 : 22)} 0 0 0;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    margin: ${(p) => getRem(p.index === 0 ? 22 : 10)} 0 0 0;
  }
`;

export const ServiceIncidentDescriptionWrapper = styled.div`
  font-size: ${getRem(12)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.black90};

  button {
    font-size: ${getRem(12)};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(p) => p.theme.colors.black90};
    text-decoration: underline;
  }
`;

export const ServiceIncidentIconWrapper = styled(SVGWrapper).attrs((p) => ({
  fill: p.theme.colors.dhlRed,
  hoverFill: p.theme.colors.dhlRed,
}))``;

export const ShipmentDetailsWrapper = styled.div`
  padding: 0 0 2.5rem;
  position: relative;
  z-index: 2;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    padding: 0;
  }
`;
