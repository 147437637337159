import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Direction, Tooltip } from "react-lib";
import messages from "./EtaPredictionIcon.messages";
import * as S from "./EtaPredictionIcon.styles";

const smartEstimationDeviation = 48;
const tooltipAlignmentOrder = [
  Direction.RIGHT,
  Direction.DOWN,
  Direction.UP,
  Direction.LEFT,
  Direction.DOWN,
];

const EtaPredictionIcon = ({ className }) => {
  const intl = useIntl();

  return (
    <Tooltip
      alignmentOrder={tooltipAlignmentOrder}
      content={intl.formatMessage(messages.tooltip, {
        deviation: smartEstimationDeviation,
      })}
    >
      <S.Icon className={className} />
    </Tooltip>
  );
};

EtaPredictionIcon.propTypes = {
  className: PropTypes.string,
};

export default EtaPredictionIcon;
