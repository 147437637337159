import gql from "graphql-tag";

export const ShipmentFavoriteFragment = gql`
  fragment ShipmentFavorite on ShipmentData {
    isFavorite
  }
`;

export const ShipmentDetailsFavoriteFragment = gql`
  fragment ShipmentDetailsFavorite on ShipmentDetails {
    isFavorite
  }
`;
