export enum EventCode {
  ORDER_RECEIVED = "ORE",
  ORDER_ACCEPTED = "ACC",
  BOOKING_CONFIRMED = "CFM",
  PICKUP = "APT",
  PICKUP_ATTEMPT = "ATP",
  RECEIVED_BY_WAREHOUSE = "RCD",
  DEPARTURE_FROM_WAREHOUSE = "DEW",
  DELIVERED_TO_PORT_OR_TERMINAL = "GIT",
  DELIVERED_TO_PORT_OR_TERMINAL__GATED_IN_PORT_OR_TERMINAL = "GIN",
  EXPORT_CUSTOMS_CLEARED = "ECR",
  LOADED_ON_BOARD = "LOB",
  DEPARTURE = "DEP",
  ARRIVAL = "ARR",
  CONSIGNEE_OR_BROKER_NOTIFIED = "TBN",
  UNLOADED_FROM_VESSEL = "ULB",
  CARRIER_RELEASE = "CRE",
  PICKUP_FROM_PORT_OR_TERMINAL__GATED_OUT_FROM_PORT_OR_TERMINAL = "GOU",
  PICKUP_FROM_PORT_OR_TERMINAL = "GOT",
  ARRIVAL_AT_DESTINATION = "ARV",
  ARRIVED_AT_WAREHOUSE = "ONH",
  READY_FOR_IMPORT_CLEARANCE = "REC",
  IMPORT_CUSTOMS_CLEARED = "CLC",
  DOCUMENT_HANDOVER = "DOS",
  DOCUMENTS_RELEASED_AT_ORIGIN = "DOR",
  SHIPMENT_HANDOVER = "SHO",
  OUT_FOR_DELIVERY = "OFD",
  DELIVERY_ATTEMPT = "ATD",
  CUSTOMER_OR_FINAL_DELIVERY = "POD",
  INVOICE_PRINTED = "INV",
  FREIGHT_RECOVERED = "FPR",
  FREIGHT_AVAILABLE = "CAV",
  DISPATCHED_FOR_PICKUP = "DFP",
  READY_FOR_COLLECTION_BY_CONSIGNEE = "RFC",
  ARRIVED_AT_DELIVERY = "AAD",
  LOADED_INTO_FERRY = "LOF",
  ON_HOLD_IN_CUSTOMS = "CHS",
  RELEASED_BY_CUSTOMS = "CHE",
  FIXED_DELIVERY_DATE = "FLD",
  PLANNED_SHIPMENT_COMPLETION = "SLA",
  PICKUP_ARRANGED = "PAR",
  ARRIVED_AT_PICKUP = "AAP",
  ARRIVED_AT_DESTINATION_BORDER = "DBA",
  ARRIVED_AT_ORIGIN_BORDER = "OBA",
  DEPARTURE_FROM_DESTINATION_BORDER = "DBD",
  DEPARTURE_FROM_ORIGIN_BORDER = "OBD",
  CARGO_LOADED = "CLO",
  IMPORT_CUSTOMS_FILED = "CUS",
  EMPTY_CONTAINER_RETURNED = "DHR",
}

export const eventCodesNotFulfillingPreviousEvents = [
  EventCode.CONSIGNEE_OR_BROKER_NOTIFIED,
  EventCode.IMPORT_CUSTOMS_CLEARED,
  EventCode.IMPORT_CUSTOMS_FILED,
  EventCode.EXPORT_CUSTOMS_CLEARED,
  EventCode.READY_FOR_IMPORT_CLEARANCE,
];
