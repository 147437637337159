import { FeatureFlagName } from "constants/getConfigClient";
import { SupportCenterRoutes } from "constants/paths";
import useIsFeatureAvailable from "hooks/useIsFeatureAvailable";
import React from "react";
import { useIntl } from "react-intl";
import SvgHelpline from "react-lib/es/icons/SvgHelpline";
import messages from "./SupportCenterTopBarButton.messages";
import * as S from "./SupportCenterTopBarButton.styles";

const SupportCenterTopBarButton: React.FC = () => {
  const intl = useIntl();
  const isSupportCenterEnabled = useIsFeatureAvailable(
    FeatureFlagName.CUSTOMER_SUPPORT_CENTER
  );

  return isSupportCenterEnabled ? (
    <S.LinkAsButton to={SupportCenterRoutes.DASHBOARD}>
      <S.Label>{intl.formatMessage(messages.support)}</S.Label>
      <SvgHelpline height={24} width={24} />
    </S.LinkAsButton>
  ) : null;
};

export default SupportCenterTopBarButton;
