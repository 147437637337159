export const quoteAndBookPaths = [
  "/get-a-quote",
  "/offers",
  "/offers/:id",
  "/booking",
  "/booking/:step",
  "/continue-with-quote/:id",
  "/continue-with-quote",
];

export enum ShipmentsRoutes {
  SHIPMENTS = "/new-shipments",
  SHIPMENT_DETAILS = "/new-shipments/:id/:tab?",
  PRINT_SHIPMENT_DETAILS = "/new-shipments/print/:id/",
}

export enum SupportCenterRoutes {
  DASHBOARD = "/support",
}
