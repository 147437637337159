import { ReactComponent as StarFilledSvg } from "assets/icons/myDHLi/star-filled.svg";
import { ReactComponent as StarOutlineSvg } from "assets/icons/myDHLi/star.svg";
import { colors } from "react-lib";
import styled, { css } from "styled-components";

const svgCss = css`
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;

  &:hover {
    fill: ${colors.postYellow};
  }
`;

export const StarFilled = styled(StarFilledSvg)`
  fill: ${colors.postYellow};
  ${svgCss};
`;

export const StarOutlined = styled(StarOutlineSvg)`
  fill: ${colors.black};
  ${svgCss};
`;
