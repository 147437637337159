import { columnToFilter } from "constants/columns";
import { LIST_TYPE } from "constants/common";
import { filterValues, quotesColumnToFilter } from "constants/quotesColumns";
import { messages } from "components/organisms/ShipmentDetails/ShipmentDetails.messages";
import { countryCodes } from "data/countries";
import includes from "lodash/includes";
import React from "react";
import { useIntl } from "react-intl";
import get from "utils/get";
import { shipmentPhasesLabels } from "../../../data/shipmentDetailTypes";
import { getFormattedDate } from "../ShipmentRow/helper/getCellContentFunctions";

export const getIsDate = (item, module) => {
  if (module === LIST_TYPE.QUOTES) {
    return includes(["CreationDate", "ExpirationDate"], item);
  } else {
    return includes(
      [
        "Departure",
        "Arrival",
        "EstimatedPickup",
        "ActualPickup",
        "EstimatedDeparturePort",
        "ActualDeparturePort",
        "EstimatedArrivalPort",
        "ActualArrivalPort",
        "EstimatedCustomerDelivery",
        "DocumentHandover",
      ],
      item
    );
  }
};

const isDateRange = (obj) => {
  if (typeof obj !== "object" || obj === null) return false;
  return obj.from && obj.to;
};

const mapFilterValueToRender = (item, filterValue) => {
  switch (item) {
    case "Service":
      return filterValues[item][filterValue];
    case "OriginCountry":
    case "DestinationCountry":
    case "BillingCountry":
      return countryCodes[filterValue];

    default:
      return filterValue;
  }
};

const ShipmentDelayStatusFilterValue = ({ filterValue }) => {
  const intl = useIntl();
  return !filterValue
    ? ""
    : messages[`shipmentDelayStatus_${filterValue}`]
    ? intl.formatMessage(messages[`shipmentDelayStatus_${filterValue}`])
    : filterValue;
};

export const getAppliedFilterText = (item, userSettings, module, intl) => {
  if (module === LIST_TYPE.QUOTES) {
    if (getIsDate(item, module)) {
      const fromDate = get(
        userSettings,
        "quotesFilter." + quotesColumnToFilter[item][0]
      );
      const toDate = get(
        userSettings,
        "quotesFilter." + quotesColumnToFilter[item][1]
      );
      return fromDate + " - " + toDate;
    } else {
      const filterValue = get(
        userSettings,
        "quotesFilter." + quotesColumnToFilter[item]
      );
      const filterValueRender = mapFilterValueToRender(item, filterValue);
      if (typeof filterValueRender === "string") return filterValueRender;
    }
    return item;
  } else {
    const filterValue = get(
      userSettings,
      "shipmentFilter." + columnToFilter[item]
    );
    if (item === "Status") {
      return <ShipmentDelayStatusFilterValue filterValue={filterValue} />;
    } else if (item === "Phase") {
      return shipmentPhasesLabels[filterValue];
    }
    if (typeof filterValue === "string") return filterValue;
    if (isDateRange(filterValue))
      return (
        getFormattedDate(intl)(filterValue.from) +
        " - " +
        getFormattedDate(intl)(filterValue.to)
      );
    return item;
  }
};
