import styled from "styled-components";
import { getRem } from "utils/css";

export const Wrapper = styled.div`
  line-height: 1.6;
  padding: ${(props) => getRem((props.padding || 1) * 10)} 0;
`;

export const Label = styled.span`
  font-weight: 300;
`;
