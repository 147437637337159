import * as C from "./constants";

export const shipmentsReducer = (
  state: C.ShipmentsState = C.initialState,
  action: C.ShipmentsAction
): C.ShipmentsState => {
  switch (action.type) {
    case C.ShipmentsActionType.SET_SHIPMENTS_LIST_FILTERS: {
      return {
        ...state,
        listFilters: action.listFilters,
      };
    }
    case C.ShipmentsActionType.SET_SHIPMENTS_LIST_QUERY: {
      return {
        ...state,
        listFilters: { ...state.listFilters, query: action.query },
      };
    }
    case C.ShipmentsActionType.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      };
    }
    case C.ShipmentsActionType.SET_LIST_SORT: {
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          order: [{ field: action.field, order: action.order }],
        },
      };
    }
    case C.ShipmentsActionType.TOGGLE_REFERENCE_NUMBERS_VISIBILITY: {
      return {
        ...state,
        visibleReferenceNumbers: {
          ...state.visibleReferenceNumbers,
          [action.referenceType]:
            !state.visibleReferenceNumbers[action.referenceType],
        },
      };
    }
    case C.ShipmentsActionType.SET_ACTIVE_SWAPPABLE_COLUMN: {
      return {
        ...state,
        activeSwappableColumn: action.activeSwappableColumn,
      };
    }
    default:
      return state;
  }
};
