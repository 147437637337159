import Prefixes from "constants/shipments";
import { useQuery } from "@apollo/client";
import SVGWrapper from "components/atoms/SVGWrapper";
import {
  ArrivalDetails,
  CheckMarkIconWrapper,
  HouseBillValue,
  ProgressStatusText,
} from "components/organisms/ShipmentDetails/ShipmentDetails.styles";
import loadSingleShipmentWithoutMapDetails from "queries/Track/loadSingleShipmentWithoutMapDetails";
import React, { useLayoutEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import get from "utils/get";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/myDHLi/checkmark.svg";
import { ReactComponent as ChevronBackIcon } from "../../assets/icons/myDHLi/chevron-back.svg";
import DataLabel from "../../components/atoms/DataLabel";
import H3 from "../../components/atoms/H3";
import Headline from "../../components/atoms/Headline";
import Loading from "../../components/molecules/Loader/Loading";
import ProgressBar from "../../components/molecules/ProgressBar/ProgressBar";
import DetailsTabContent from "../../components/organisms/DetailsTabContent/DetailsTabContent";
import RouteSteps from "../../components/organisms/Route/RouteSteps";
import ProgressStatus from "../../components/organisms/ShipmentDetails/Partials/ProgressStatus";
import ProgressTimeStatus from "../../components/organisms/ShipmentDetails/Partials/ProgressTimeStatus";
import ShipmentTitleBar from "../../components/organisms/ShipmentDetails/Partials/ShipmentTitleBar";
import { NumberOfLevelsInShipmentProgressBar } from "../../components/organisms/ShipmentRow/helper/constants";
import getCellContentSmall from "../../components/organisms/ShipmentRow/helper/getCellContentSmall";
import {
  shipmentDelayStatuses,
  shipmentPhases,
} from "../../data/shipmentDetailTypes";
import text from "../../data/text";
import { getRem } from "../../utils/css";
import { messages } from "./messages";
import { GoBackWrapper, PrintLayoutWrapper } from "./Styles";
// import PropTypes from 'prop-types';

const PrintSingleShipment = (props) => {
  const intl = useIntl();
  const id = get(props, "match.params.id");
  const isDemo =
    useSelector((state) => state.userSettings.visibilityScope.demo) || false;
  const { data, loading } = useQuery(loadSingleShipmentWithoutMapDetails, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    variables: {
      shipmentId: id,
      demo: isDemo,
    },
  });

  useLayoutEffect(() => {
    if (data) {
      window.setTimeout(() => {
        window.print();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [data && data.getShipmentDetails]);

  if (loading && !data) {
    return <Loading />;
  } else {
    const shipmentData = get(data, "getShipmentDetails.data");
    const isShipmentCompleted =
      shipmentData.transitStatus === shipmentPhases.COMPLETED;
    const lastEventName = shipmentData?.lastEvent?.code
      ? intl.formatMessage({
          id: Prefixes.milestone + shipmentData.lastEvent.code,
          defaultMessage: shipmentData.lastEvent.code,
        })
      : undefined;

    return (
      <PrintLayoutWrapper>
        <GoBackWrapper
          onClick={() => {
            window.history.back();
          }}
        >
          <SVGWrapper
            width={getRem(30)}
            height={getRem(30)}
            fill="rgba(0, 0, 0, 0.6)"
          >
            <ChevronBackIcon />
          </SVGWrapper>
          <span>{intl.formatMessage(messages.shipmentGoBackButton)}</span>
        </GoBackWrapper>
        <ShipmentTitleBar shipmentData={shipmentData} />
        <ProgressStatus>
          {isShipmentCompleted && (
            <CheckMarkIconWrapper>
              <CheckMarkIcon />
            </CheckMarkIconWrapper>
          )}
          <ProgressStatusText isCompleted={isShipmentCompleted}>
            {isShipmentCompleted
              ? intl.formatMessage(messages.completed)
              : lastEventName || get(shipmentData, "lastEvent.location")
              ? `${lastEventName || "--"}, ${get(
                  shipmentData,
                  "lastEvent.location",
                  "--"
                )}`
              : intl.formatMessage(messages.planned)}
          </ProgressStatusText>
        </ProgressStatus>
        <ProgressBar
          total={NumberOfLevelsInShipmentProgressBar}
          completed={getCellContentSmall({
            key: "Progress",
            item: shipmentData,
            intl,
          })}
          delayed={shipmentData.delayStatus === shipmentDelayStatuses.DELAYED}
        />
        <ProgressTimeStatus isCompleted={isShipmentCompleted}>
          {getCellContentSmall({
            key: "DelayStatus",
            item: shipmentData,
            intl,
          })}
        </ProgressTimeStatus>
        <Headline priority={5}>
          <ArrivalDetails>
            {getCellContentSmall({
              key: "Arrival_details",
              item: shipmentData,
              intl,
            })}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {intl.formatMessage(
              shipmentData.actualArrival
                ? messages.shipmentActualArrivalPort
                : messages.shipmentEstimatedArrivalPort
            )}
          </ArrivalDetails>
        </Headline>
        <div>
          <DataLabel>{text.houseBill}</DataLabel>{" "}
          <HouseBillValue>{shipmentData.houseBill}</HouseBillValue>
        </div>
        <H3 />
        <H3>{intl.formatMessage(messages.shipmentJourneyTitleRoute)}</H3>
        <RouteSteps
          print
          {...props}
          pathPoints={get(
            shipmentData,
            `transportUnitDetails[0].pathPoints`,
            []
          )}
          transportMode={shipmentData.transportMode}
          shipmentData={shipmentData}
        />
        <H3 />
        <H3>{intl.formatMessage(messages.shipmentJourneyTitleDetails)}</H3>
        <DetailsTabContent
          {...props}
          print
          shipmentData={shipmentData}
          currentContainerNumber={0}
        />
      </PrintLayoutWrapper>
    );
  }
};
PrintSingleShipment.defaultProps = {};
PrintSingleShipment.propTypes = {};

export default PrintSingleShipment;
