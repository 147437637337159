import styled from "styled-components";
import { getRem } from "../../utils/css";

const ActionText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  padding: ${(p) => (p.noPadding ? "0" : `0 ${getRem(10)}`)};
`;

export default ActionText;
