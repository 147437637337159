import globalDateMessages from "constants/dateMessages";
import timeDiffToTZAbbr from "constants/timeDiffToTZAbbr";
import moment from "moment-timezone";
import get from "utils/get";

export const isActualTimeAvailable = (event) => {
  return !!get(event, "actual", false);
};

export const isPlannedTimeAvailable = (event) => {
  return !!get(event, "planned", false);
};

export const isEstimatedTimeAvailable = (event) => {
  return !!get(event, "estimated", false);
};

export const getFormattedDateTime =
  (intl) =>
  (dateTime, withTimeZone = true) => {
    return withTimeZone
      ? timeDiffToTZAbbr(
          moment
            .parseZone(dateTime)
            .format(intl.formatMessage(globalDateMessages.longDatetime)) // Todo: add format HH:mm Z when we decide to enable timezone
        )
      : moment
          .parseZone(dateTime)
          .format(intl.formatMessage(globalDateMessages.longDatetime));
  };
