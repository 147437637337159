import { shipmentEventFlags } from "data/shipmentDetailTypes";
import React from "react";
import { useIntl } from "react-intl";
import { Direction, Tooltip } from "react-lib";
import { messages } from "../Route.messages";
import * as S from "./Event.styles";

interface EventDepartureStatusBadgeProps {
  event: {
    flags?: string[];
  };
}

const EventDepartureStatusBadge: React.FC<EventDepartureStatusBadgeProps> = ({
  event,
}) => {
  const intl = useIntl();

  if (!Array.isArray(event.flags)) return null;

  return (
    <>
      {event.flags.includes(shipmentEventFlags.CONFIRMED_ESTIMATE) && (
        <S.StyledBadge outline>
          {intl.formatMessage(messages.confirmedBooking)}
        </S.StyledBadge>
      )}

      {event.flags.includes(shipmentEventFlags.INITIAL_ESTIMATE) && (
        <Tooltip
          content={intl.formatMessage(messages.subjectToChange)}
          alignmentOrder={[Direction.RIGHT, Direction.DOWN, Direction.UP]}
        >
          <S.StyledBadge outline>
            {intl.formatMessage(messages.initialBooking)}
          </S.StyledBadge>
        </Tooltip>
      )}
    </>
  );
};

export default EventDepartureStatusBadge;
