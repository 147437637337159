import Row from "components/atoms/Row";
import { colors } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled, { css } from "styled-components";
import { LeftBorder } from "../Route.sharedStyles";

export const SeparatorRow = styled(Row)<{
  isQuoteAndBook: boolean;
  isInMapView: boolean;
}>`
  > [data-name="col-10"] {
    padding-left: 0.625rem !important;
    padding-right: 1.875rem !important;
  }
  padding-top: ${({ isQuoteAndBook }) =>
    isQuoteAndBook ? "0.3125rem" : "0.5rem"};
  padding-bottom: ${({ isQuoteAndBook }) =>
    isQuoteAndBook ? "0.5rem" : "1.5rem"};

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    padding-top: ${({ isQuoteAndBook, isInMapView }) =>
      isQuoteAndBook ? 0 : isInMapView ? "0.5rem" : "0.75rem"} !important;
    padding-bottom: ${({ isQuoteAndBook, isInMapView }) =>
      isQuoteAndBook ? "0.625rem" : isInMapView ? "1rem" : "2rem"} !important;
  }
`;

export const SeparatorBottomBorder = styled.hr`
  border: none;
  border-bottom: 1px solid ${colors.gray20};
`;

export const SeparatorLeftBorder = styled(LeftBorder)`
  top: -1.5rem;
  bottom: -2.25rem;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    bottom: -3.125rem;
  }
`;

export const IconCol = styled.div<{
  isInMapView: boolean;
}>`
  flex: 0 0 3.75rem;
  max-width: 3.75rem;
  padding-right: 1.25rem;
  position: relative;
  ${({ isInMapView }) =>
    isInMapView
      ? css`
          flex: 0 0 3.125rem;
          max-width: 3.125rem;
          padding-right: 0.625rem;
        `
      : ``};

  @media (max-width: ${SCREEN_SIZES.until.md}) {
    flex: 0 0 3.125rem;
    max-width: 3.125rem;
    padding-right: 0.625rem;
  }
`;

export const TextCol = styled.div<{
  isQuoteAndBook: boolean;
}>`
  flex: 1;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    margin-right: ${({ isQuoteAndBook }) => (isQuoteAndBook ? 0 : "3.75rem")};
  }
`;
