import {
  getFontFamily,
  getFontColor,
  getPrimaryColor,
  getFontSize,
} from "constants/DHLPatternLibraryTheme";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

export const COLORS = {
  RED: "Red",
  DEFAULT: "Default",
};

export const PRIORITIES = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

export const WEIGHTS = {
  200: 200,
  400: 400,
  700: 700,
  800: 800,
};

// #region Component Styles
const getFontSizeFromPriority = (priority) => {
  switch (priority) {
    case PRIORITIES["1"]:
      return getFontSize("huge");

    case PRIORITIES["2"]:
      return getFontSize("xxbig");

    case PRIORITIES["3"]:
      return getFontSize("xbig");

    case PRIORITIES["4"]:
      return getFontSize("big");

    case PRIORITIES["5"]:
    default:
      return getFontSize("regular");
  }
};

const Container = styled.h1`
  ${({ fontColor, inlined, weight, noMargin, priority }) => css`
    color: ${fontColor === COLORS.DEFAULT
      ? getFontColor("default")
      : getPrimaryColor("400")};

    display: ${inlined ? "inline-block" : undefined};
    font-family: ${getFontFamily()};
    font-size: ${getFontSizeFromPriority(priority)};
    font-weight: ${weight};
    margin-bottom: ${noMargin ? 0 : undefined};
    margin-top: 0;
  `}
`;
// #endregion

const Headline = ({ children, color, inlined, noMargin, priority, weight }) => (
  <Container
    as={`h${priority}`}
    fontColor={color}
    inlined={inlined}
    noMargin={noMargin}
    priority={priority}
    weight={weight}
  >
    {children}
  </Container>
);

Headline.propTypes = {
  /** Child to render */
  children: PropTypes.node.isRequired,
  /** Component will be rendered in this which color? */
  color: PropTypes.oneOf(Object.values(COLORS)),
  /** If set will set the headline to inline-block */
  inlined: PropTypes.bool,
  /** Removes the margin from the bottom of the component */
  noMargin: PropTypes.bool,
  /** It's going to be use to decide which headline tag to use. (h1-h5) */
  priority: PropTypes.oneOf(Object.values(PRIORITIES)).isRequired,
  /** What should be the header's font-weight? */
  weight: PropTypes.oneOf(Object.values(WEIGHTS)),
};

Headline.defaultProps = {
  color: COLORS.DEFAULT,
  inlined: false,
  noMargin: false,
  weight: 800,
};

export default Headline;
