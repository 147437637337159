import Container from "components/atoms/Container";
import PropTypes from "prop-types";
import React from "react";
import get from "utils/get";
import Event from "./Partials/Event";
import Separator from "./Partials/Separator";
import TitleRow from "./Partials/TitleRow";

const RouteStep = (props) => {
  const events = get(props, "pathPoint.events", []);
  let currentEventRelevant = false;
  return (
    <Container fluid>
      <TitleRow
        pathPoint={props.pathPoint}
        transportMode={props.transportMode}
        inMapView={props.inMapView}
        isLast={props.isLast}
        isFutureEvent={props.isFutureEventTitle}
        isFirst={props.isFirst}
        shipmentData={props.shipmentData}
        isQuoteAndBook={props.isQuoteAndBook}
      />
      {events.map((event, i) => {
        currentEventRelevant = !!(
          get(event, "actual") ||
          get(event, "estimated") ||
          get(event, "planned")
        );
        return (
          <Event
            key={event.code}
            event={event}
            shouldContinue={props.separator || i !== events.length - 1}
            latestUpdatedEvent={props.latestUpdatedEvent}
            pathPointType={get(props, "pathPoint.typeCode")}
            locationCode={get(props, "pathPoint.location.unLoCode", "")}
            inMapView={props.inMapView}
            allEvents={props.allEvents}
            isFutureEvent={event.isFutureEvent}
            isQuoteAndBook={props.isQuoteAndBook}
          />
        );
      })}
      {props.separator ? (
        <Separator
          solid={currentEventRelevant}
          color={
            get(events, "[" + (events.length - 1) + "].actual", false)
              ? "#76bd22"
              : "#cccccc"
          }
          inMapView={props.inMapView}
          isFutureEvent={props.isFutureEventSeparator}
          isQuoteAndBook={props.isQuoteAndBook}
        />
      ) : null}
    </Container>
  );
};

RouteStep.defaultProps = {
  pathPoint: {},
  relevant: false,
};
RouteStep.propTypes = {
  pathPoint: PropTypes.object,
  relevant: PropTypes.bool,
  separator: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
};

export default RouteStep;
