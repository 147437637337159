import { Badge, colors } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled, { css } from "styled-components";

export const StyledBadge = styled(Badge)`
  padding: 0 0.25rem;
  line-height: 1rem;
`;

export const TextCol = styled.div<{ isInMapView: boolean }>`
  flex: 1;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    padding-right: ${({ isInMapView }) => (isInMapView ? 0 : "3.75rem")};
  }
`;

export const IconCol = styled.div<{
  isInMapView: boolean;
  isQuoteAndBook: boolean;
}>`
  flex: 0 0 3.75rem;
  max-width: 3.75rem;
  padding-right: 1.75rem;
  padding-left: ${({ isQuoteAndBook }) =>
    isQuoteAndBook ? "0.625rem" : "0.5rem"};
  position: relative;
  ${({ isInMapView }) =>
    isInMapView
      ? css`
          flex: 0 0 3.125rem;
          max-width: 3.125rem;
          padding-right: 1.125rem;
          padding-left: 0.5rem;
        `
      : ``};

  @media (max-width: ${SCREEN_SIZES.until.md}) {
    flex: 0 0 3.125rem;
    max-width: 3.125rem;
    padding-right: 1.125rem;
    padding-left: 0.5rem;
  }
`;

export const SubText = styled.p<{ isInMapView: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  font-size: 0.75rem;
  color: ${colors.gray60};
  margin: 0;
  padding: ${({ isInMapView }) => (isInMapView ? "0.5rem" : "0.625rem")} 0 0 0;
`;

export const Title = styled.span<{
  fontWeight: string;
  fontSize: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${colors.black};
  line-height: 1.25;
`;
