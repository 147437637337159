import gql from "graphql-tag";

const QUOTE_ALERTS = gql`
  query QuoteAlertsListQuery($locale: String!) {
    getQuoteAlerts(locale: $locale) {
      id
      text
      title
      url
    }
  }
`;

export default QUOTE_ALERTS;
