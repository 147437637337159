import styled, { css } from "styled-components";

const StrongText = styled.span`
  line-height: 1.5;
  font-weight: bold;
  font-size: ${(p) => (p.size ? p.size : "14")}px;
  ${(p) =>
    p.centerVertically
      ? css`
          vertical-align: middle;
        `
      : null}
`;

export default StrongText;
