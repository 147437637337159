import { AutoColMd, GrowCol } from "components/atoms/Col";
import Row from "components/atoms/Row";
import SVGWrapper from "components/atoms/SVGWrapper";
import { freightTypes, Icon, locationTypes } from "data/shipmentDetailTypes";
import checkIsShipmentFreight from "helpers/isShipmentFreight";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { VGap } from "react-lib";
import PlaceholderIcon from "react-lib/es/icons/SvgLocationWarehouse";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import { useMediaQuery } from "react-responsive";
import get from "utils/get";
import { messages } from "../Route.messages";
import { LeftBorder, StepRow } from "../Route.sharedStyles";
import * as S from "./TitleRow.styles";

const useGetAddresses = ({
  shipperStreet,
  shipperZip,
  shipperCity,
  shipperCountry,
  consigneeStreet,
  consigneeZip,
  consigneeCity,
  consigneeCountry,
}) => {
  return useMemo(() => {
    const shipperAddressArray = [
      shipperStreet,
      shipperZip,
      shipperCity,
      shipperCountry,
    ].filter((i) => !!i);
    const consigneeAddressArray = [
      consigneeStreet,
      consigneeZip,
      consigneeCity,
      consigneeCountry,
    ].filter((i) => !!i);
    return {
      shipperAddress: shipperAddressArray.length
        ? shipperAddressArray.join(", ")
        : "",
      consigneeAddress: consigneeAddressArray.length
        ? consigneeAddressArray.join(", ")
        : "",
    };
  }, [
    shipperStreet,
    shipperZip,
    shipperCity,
    shipperCountry,
    consigneeStreet,
    consigneeZip,
    consigneeCity,
    consigneeCountry,
  ]);
};

const TitleRow = (props) => {
  const location = get(props.pathPoint, "location", {});
  let locationType = get(props, "pathPoint.locationType");
  if (locationType === locationTypes.TRANSSHIPMENT_PORT) {
    locationType =
      props.transportMode === freightTypes.OCEAN
        ? locationTypes.PORT
        : props.transportMode === freightTypes.AIR
        ? locationTypes.AIRPORT
        : "";
  }
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.until.lg });
  const intl = useIntl();
  const isFirstOrLast = props.isFirst || props.isLast;
  const { shipperAddress, consigneeAddress } = useGetAddresses(
    props.shipmentData || {}
  );
  const isFreight = checkIsShipmentFreight(props.shipmentData?.productBu);

  let typeCode = "";
  if (!props?.pathPoint?.hideTypeCode) {
    const typeCodeMessageKey = `routePathPointType_${props?.pathPoint?.typeCode}`;
    const typeCodeMessage = isFreight
      ? messages[typeCodeMessageKey + "_Freight"] ||
        messages[typeCodeMessageKey]
      : messages[typeCodeMessageKey];
    typeCode = typeCodeMessage
      ? intl.formatMessage(typeCodeMessage)
      : props?.pathPoint?.typeCode || "";
  }

  const iconSize = (() => {
    if (!props.isQuoteAndBook) {
      return "1.5rem";
    }

    return isMobile ? "1.25rem" : "1.75rem";
  })();

  return (
    <StepRow
      isInMapView={props.inMapView}
      isFirstOrLast={isFirstOrLast}
      isQuoteAndBook={props.isQuoteAndBook}
    >
      <S.IconCol isInMapView={props.inMapView}>
        <S.PathPointLogoCircle
          isQuoteAndBook={props.isQuoteAndBook}
          isMobile={isMobile}
        >
          <SVGWrapper height={iconSize} width={iconSize}>
            {Icon[locationType] || <PlaceholderIcon />}
          </SVGWrapper>
        </S.PathPointLogoCircle>
        {!get(props, "pathPoint.events.length") &&
        get(props, "isLast") ? null : (
          <LeftBorder
            isFutureEvent={props.isFutureEvent}
            isQuoteAndBook={props.isQuoteAndBook}
          />
        )}
      </S.IconCol>
      <S.TextCol
        isInMapView={props.inMapView}
        isMobile={isMobile}
        isQuoteAndBook={props.isQuoteAndBook}
      >
        <Row style={{ alignItems: "center" }}>
          <GrowCol>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <S.Title
                shouldBeSmall={
                  props.inMapView || isMobile || props.isQuoteAndBook
                }
              >
                {props?.pathPoint?.customTitle ||
                  `${location?.name} - ${location?.countryName}`}
              </S.Title>
              {isFirstOrLast && (
                <VGap
                  top={props.isQuoteAndBook && isMobile ? 10 : 4}
                  bottom={isMobile ? 5 : props.isQuoteAndBook ? 4 : 30}
                >
                  <S.Address isQuoteAndBook={props.isQuoteAndBook}>
                    {props.pathPoint?.customAddress ||
                      (props.isFirst ? shipperAddress : consigneeAddress)}
                  </S.Address>
                </VGap>
              )}
            </div>
          </GrowCol>
          <AutoColMd
            lineHeight={
              props.isQuoteAndBook && isMobile && !location?.unLoCode
                ? "0.5rem"
                : "inherit"
            }
          >
            <S.CodeLabel>
              {typeCode}
              {location?.unLoCode
                ? `${props?.pathPoint?.hideTypeCode ? "" : " | "}${
                    location?.unLoCode === "XXXXX" ? "--" : location?.unLoCode
                  }`
                : ""}
            </S.CodeLabel>
          </AutoColMd>
        </Row>
      </S.TextCol>
    </StepRow>
  );
};

TitleRow.defaultProps = {
  pathPoint: {},
};
TitleRow.propTypes = {
  pathPoint: PropTypes.object,
};

export default TitleRow;
