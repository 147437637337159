import Container from "components/atoms/Container";
import Row from "components/atoms/Row";
import times from "lodash/times";
import PropTypes from "prop-types";
import React from "react";
import { getRem } from "utils/css";
import SingleBar from "./SingleBar";

const ProgressBar = (props) => {
  return (
    <div style={{ marginBottom: props.small ? getRem(4) : getRem(10) }}>
      <Container fluid>
        <Row>
          {times(props.total, (i) => (
            <SingleBar
              delayed={props.delayed}
              small={props.small}
              key={i}
              completed={i < props.completed}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};
ProgressBar.defaultProps = {
  total: 3,
  completed: 2,
  delayed: false,
  small: false,
};

ProgressBar.propTypes = {
  total: PropTypes.number,
  completed: PropTypes.number,
  small: PropTypes.bool,
  delayed: PropTypes.bool,
};

export default ProgressBar;
