import PropTypes from "prop-types";
import React from "react";
import * as S from "./Separator.styles";

const Separator = ({
  inMapView,
  isFutureEvent,
  onlyHorizontalLine,
  isQuoteAndBook,
}) => {
  return (
    <S.SeparatorRow isInMapView={inMapView} isQuoteAndBook={isQuoteAndBook}>
      {!onlyHorizontalLine && (
        <S.IconCol isInMapView={inMapView}>
          <S.SeparatorLeftBorder
            isFutureEvent={isFutureEvent}
            isQuoteAndBook={isQuoteAndBook}
          />
        </S.IconCol>
      )}
      <S.TextCol isQuoteAndBook={isQuoteAndBook}>
        <S.SeparatorBottomBorder />
      </S.TextCol>
    </S.SeparatorRow>
  );
};

Separator.defaultProps = { onlyHorizontalLine: false };

Separator.propTypes = {
  solid: PropTypes.bool,
  color: PropTypes.string,
  onlyHorizontalLine: PropTypes.bool,
};

export default Separator;
