import styledTheme from "constants/styledTheme";
import { ReactComponent as MenuIcon } from "assets/icons/myDHLi/menu-burger.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import React from "react";

const BurgerMenu = (props) => {
  return (
    <SVGWrapper
      fill={styledTheme.colors.dhlRed}
      hoverFill={styledTheme.colors.hoverRed}
      as="button"
      aria-label="Menu"
      onClick={() => props.onClick()}
    >
      <MenuIcon />
    </SVGWrapper>
  );
};

export default BurgerMenu;
