import { ReactComponent as ForwardSvg } from "assets/icons/myDHLi/arrow-forward.svg";
import FreightIcon from "components/atoms/Icons/FreightIcon";
import ShipmentFavoriteButton from "components/molecules/ShipmentFavoriteButton/ShipmentFavoriteButton";
import styled from "styled-components";
import { SCREEN_SIZES } from "utils/css";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.875rem 0 1.25rem;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    margin-top: ${({ inMapView }) => (inMapView ? "1.875rem" : "2.5rem")};
  }
`;

export const Icon = styled(FreightIcon)`
  padding-right: 0.875rem;
  position: relative;

  & > svg {
    // with 28px it gets blurry
    width: 1.6875rem;
    height: 1.6875rem;
  }
`;

export const FavoriteButton = styled(ShipmentFavoriteButton)`
  margin-left: auto;
`;

export const LocationText = styled.span`
  font-size: 1.25rem;
  font-weight: 900;
  font-stretch: condensed;
  line-height: 1.5rem;
`;

export const ForwardIcon = styled(ForwardSvg)`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.25rem;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    // Arrow size should be 27px
    width: 1.6875rem;
    height: 1.6875rem;
  }
`;
