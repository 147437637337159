import DataLabelWithHelp from "components/molecules/DataLabelWithHelp/DataLabelWithHelp";
import { unitMessages } from "components/organisms/ShipmentDetails/ShipmentDetails.messages";
import { TopSectionWrapper } from "components/organisms/ShipmentDetails/ShipmentDetails.styles";
import checkIsShipmentFreight from "helpers/isShipmentFreight";
import replace from "lodash/replace";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Direction, Tooltip, TooltipTheme } from "react-lib";
import SvgInformation from "react-lib/es/icons/SvgInformation";
import get from "utils/get";
import getFixedNumber from "utils/getFixedNumber";
import ContainerList from "./ContainerList";
import { messages } from "./DetailsTabContent.messages";
import * as S from "./DetailsTabContent.styles";

const tooltipsDirections = [
  Direction.RIGHT,
  Direction.DOWN,
  Direction.LEFT,
  Direction.UP,
];

const DetailsTabContent = ({
  shipmentData,
  currentContainerNumber,
  print,
  inMapView,
}) => {
  const intl = useIntl();

  return (
    <TopSectionWrapper print={print} inMapView={inMapView}>
      <S.Wrapper print={print}>
        {checkIsShipmentFreight(shipmentData?.productBu) && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.orderCodeLabel)}
            />
            <div>
              <S.StrongText size={16}>{shipmentData.houseBill}</S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "carrierNames.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.carrierName)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "carrierNames", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.shipperName && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.shipperName)}
            />
            <div>
              <S.StrongText size={16}>{shipmentData.shipperName}</S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.consigneeName && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.consigneeName)}
            />
            <div>
              <S.StrongText size={16}>
                {shipmentData.consigneeName}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.customerName && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.customerName)}
            />
            <div>
              <S.StrongText size={16}>{shipmentData.customerName}</S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "references.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.customerReference)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "references", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "customsEntryNumber.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.customsEntryNumber)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "customsEntryNumber", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "masterBills.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.masterBills)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "masterBills", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "flightNumbers.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.flightNumbers)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "flightNumbers", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "vesselNames.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.vesselNames)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "vesselNames", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "containerNumbers.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(
                checkIsShipmentFreight(shipmentData?.productBu)
                  ? messages.packageID
                  : messages.containerNumber
              )}
            />
            <ContainerList
              containers={get(shipmentData, "containerNumbers", [])}
              currentContainer={currentContainerNumber}
            />
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "containerSizes.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.containerSizes)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "containerSizes", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {get(shipmentData, "goodsDescriptions.length", 0) > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.goodsDescriptions)}
            />
            <div>
              <S.StrongText size={16}>
                {get(shipmentData, "goodsDescriptions", []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData?.incoTerms?.name && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp label={intl.formatMessage(messages.incoTerms)} />
            <div>
              <S.StrongText size={16}>
                {shipmentData?.incoTerms?.name}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.actualWeight && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.actualWeight)}
            />
            <div>
              <S.StrongText size={16}>
                {shipmentData.actualWeight}{" "}
                {unitMessages[shipmentData.weightUnit] &&
                  intl.formatMessage(unitMessages[shipmentData.weightUnit])}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.volume && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp label={intl.formatMessage(messages.volume)} />
            <div>
              <S.StrongText size={16}>
                {shipmentData.volume}{" "}
                {unitMessages[shipmentData.volumeUnit] &&
                  intl.formatMessage(unitMessages[shipmentData.volumeUnit])}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.chargeableWeight && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.chargeableWeight)}
            />
            <div>
              <S.StrongText size={16}>
                {shipmentData.chargeableWeight}{" "}
                {unitMessages[shipmentData.weightUnit] &&
                  intl.formatMessage(unitMessages[shipmentData.weightUnit])}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {!!shipmentData.pieces && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp label={intl.formatMessage(messages.pieces)} />
            <div>
              <S.StrongText size={16}>{shipmentData.pieces}</S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {shipmentData?.loadingMeter && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.loadingMeters)}
            />
            <div>
              <S.StrongText size={16}>
                {shipmentData?.loadingMeter}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {shipmentData?.featureNames?.length > 0 && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.featureNames)}
            />
            <div>
              <S.StrongText size={16}>
                {(shipmentData?.featureNames || []).join(", ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {shipmentData?.productName && (
          <S.SingleDetailWrapper>
            <DataLabelWithHelp
              label={intl.formatMessage(messages.productName)}
            />
            <div>
              <S.StrongText size={16}>
                {replace(shipmentData?.productName, /_/g, " ")}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {shipmentData?.co2eTotal && (
          <S.SingleDetailWrapper>
            <S.InlineWrapper>
              <DataLabelWithHelp
                label={intl.formatMessage(messages.co2WtWFootprint)}
              />
              <Tooltip
                showFang={false}
                theme={TooltipTheme.WHITE}
                alignmentOrder={tooltipsDirections}
                content={intl.formatMessage(
                  messages.co2WtWFootprintDescription
                )}
              >
                <S.SVGWrapper>
                  <SvgInformation />
                </S.SVGWrapper>
              </Tooltip>
            </S.InlineWrapper>
            <div>
              <S.StrongText size={16}>
                {`${getFixedNumber(
                  shipmentData.co2eTotal
                )}  ${intl.formatMessage(messages.co2WtWFootprintUnit)}`}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
        {shipmentData?.mainLegEfficiency && (
          <S.SingleDetailWrapper>
            <S.InlineWrapper>
              <DataLabelWithHelp
                label={intl.formatMessage(messages.co2WtWEfficiency)}
              />
              <Tooltip
                showFang={false}
                theme={TooltipTheme.WHITE}
                alignmentOrder={tooltipsDirections}
                content={intl.formatMessage(
                  messages.co2WtWEfficiencyDescription
                )}
              >
                <S.SVGWrapper>
                  <SvgInformation />
                </S.SVGWrapper>
              </Tooltip>
            </S.InlineWrapper>
            <div>
              <S.StrongText size={16}>
                {`${getFixedNumber(
                  shipmentData.mainLegEfficiency
                )}  ${intl.formatMessage(messages.co2WtWEfficiencyUnit)}`}
              </S.StrongText>
            </div>
          </S.SingleDetailWrapper>
        )}
      </S.Wrapper>
    </TopSectionWrapper>
  );
};

DetailsTabContent.defaultProps = {
  shipmentData: {},
};
DetailsTabContent.propTypes = {
  shipmentData: PropTypes.object,
};

export default DetailsTabContent;
