const text = {
  collapseMenu: "Collapse Menu",
  myProfile: "My Profile",
  container: "Container",
  customerReference: "Your Reference",
  customerReferenceHelp: "Your Reference Help Text",
  containerNumber: "Container",
  containerNumberHelp: "Container Help Text",
  type: "Type",
  consigneeName: "Consignee",
  consigneeNameHelp: "Consignee Help Text",
  shipperName: "Shipper",
  shipperNameHelp: "Shipper Help Text",
  vesselNames: "Vessel",
  vesselNamesHelp: "Vessel Help Text",
  billOfLading: "Bill of Lading",
  houseBill: "House Bill",
  houseBillHelp: "House Bill Help Text",
  carrierName: "Carrier",
  carrierNameHelp: "Carrier Help Text",
  customerName: "Customer",
  customerNameHelp: "Customer Help Text",
  masterBills: "Master Bill",
  masterBillsHelp: "Master Bill Help Text",
  flightNumbers: "Flight/Voyage",
  flightNumbersHelp: "Flight/Voyage Help Text",
  containerSizes: "Container Size",
  containerSizesHelp: "Container Size Help Text",
  goodsDescriptions: "Goods Description",
  goodsDescriptionsHelp: "Goods Description Help Text",
  actualWeight: "Total Weight",
  actualWeightHelp: "Total Weight Help Text",
  volume: "Volume",
  volumeHelp: "Volume Help Text",
  chargeableWeight: "Chargeable Weight",
  chargeableWeightHelp: "Chargeable Weight Help Text",
  pieces: "Pieces",
  piecesHelp: "Pieces Help Text",
};

export default text;
