import { shipmentDelayStatuses } from "data/shipmentDetailTypes";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  timeNotAvailable: {
    id: "shipment.detail.event.timeNotAvailable",
    defaultMessage: "Time Not Available",
  },
  dateEstimated: {
    id: "shipment.detail.event.date.estimated",
    defaultMessage: "Estimated",
  },
  dateFirstEstimate: {
    id: "shipment.detail.event.date.firstEstimate",
    defaultMessage: "First Estimate",
  },
  routePathPointType_PLACE_OF_ACCEPTANCE: {
    id: "shipment.detail.route.pathPointType.PLACE_OF_ACCEPTANCE",
    defaultMessage: "Place of Acceptance",
  },
  routePathPointType_PORT_OF_LOADING: {
    id: "shipment.detail.route.pathPointType.PORT_OF_LOADING",
    defaultMessage: "Port of Loading",
  },
  routePathPointType_TRANSSHIPMENT_PORT: {
    id: "shipment.detail.route.pathPointType.TRANSSHIPMENT_PORT",
    defaultMessage: "Transshipment Port",
  },
  routePathPointType_CURRENT_LOCATION: {
    id: "shipment.detail.route.pathPointType.CURRENT_LOCATION",
    defaultMessage: "Current Location",
  },
  routePathPointType_PORT_OF_DISCHARGE: {
    id: "shipment.detail.route.pathPointType.PORT_OF_DISCHARGE",
    defaultMessage: "Port of Discharge",
  },
  routePathPointType_PLACE_OF_DELIVERY: {
    id: "shipment.detail.route.pathPointType.PLACE_OF_DELIVERY",
    defaultMessage: "Place of Delivery",
  },
  routePathPointType_PRECARRIAGE_STOP: {
    id: "shipment.detail.route.pathPointType.PRECARRIAGE_STOP",
    defaultMessage: "Pre-Carriage Stop",
  },
  routePathPointType_ONCARRIAGE_STOP: {
    id: "shipment.detail.route.pathPointType.ONCARRIAGE_STOP",
    defaultMessage: "On Carriage Stop",
  },
  routePathPointType_PORT_OF_LOADING_Freight: {
    id: "shipment.detail.route.pathPointType.PORT_OF_LOADING.Freight",
    defaultMessage: "Origin Terminal",
  },
  routePathPointType_PORT_OF_DISCHARGE_Freight: {
    id: "shipment.detail.route.pathPointType.PORT_OF_DISCHARGE.Freight",
    defaultMessage: "Destination Terminal",
  },
  routePathPointType_PortOfLoading_Freight: {
    id: "shipment.detail.route.pathPointType.PortOfLoading.Freight",
    defaultMessage: "Origin Terminal",
  },
  routePathPointType_PortOfDischarge_Freight: {
    id: "shipment.detail.route.pathPointType.PortOfDischarge.Freight",
    defaultMessage: "Destination Terminal",
  },
  routePathPointType_TRANSSHIPMENT_PORT_Freight: {
    id: "shipment.detail.route.pathPointType.TRANSSHIPMENT_PORT.Freight",
    defaultMessage: "TERMINAL",
  },
  [`shipmentDelayStatus_${shipmentDelayStatuses.DELAYED}`]: {
    id: "shipment.detail.delayStatus.delayedWithDays",
    defaultMessage:
      "Delayed {days, plural, =0 {} one {1 DAY} other {{days} DAYS}}",
  },
  [`shipmentDelayStatus_${shipmentDelayStatuses.ON_TIME}`]: {
    id: "shipment.detail.delayStatus.RouteOnTime",
    defaultMessage: "On Time",
  },
  confirmedBooking: {
    id: "shipment.detail.event.confirmedBooking",
    defaultMessage: "Confirmed Booking",
  },
  initialBooking: {
    id: "shipment.detail.event.initialBooking",
    defaultMessage: "Initial Booking",
  },
  subjectToChange: {
    id: "shipment.detail.event.subjectToChange",
    defaultMessage: "Subject to change",
  },
});
