import Prefixes from "constants/shipments";
import { ReactComponent as ErrorIcon } from "assets/icons/myDHLi/error.svg";
import Col from "components/atoms/Col";
import EtaPredictionIcon from "components/atoms/EtaPredictionIcon/EtaPredictionIcon";
import Row from "components/atoms/Row";
import SVGWrapper from "components/atoms/SVGWrapper";
import { shipmentRouteEventCodeUnknown } from "components/organisms/ShipmentDetails/ShipmentDetails.constants";
import { shipmentEventFlags } from "data/shipmentDetailTypes";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { colors, Text } from "react-lib";
import { getRem } from "utils/css";
import get from "utils/get";
import { ReactComponent as CompletedEventIcon } from "../../../../assets/icons/myDHLi/event-completed.svg";
import { ReactComponent as NextEventIcon } from "../../../../assets/icons/myDHLi/event-current.svg";
import { ReactComponent as FutureEventIcon } from "../../../../assets/icons/myDHLi/event-future.svg";
import { messages } from "../Route.messages";
import { LeftBorder, StepRow } from "../Route.sharedStyles";
import { eventCodesNotFulfillingPreviousEvents } from "./Event.constants";
import {
  getFormattedDateTime,
  isActualTimeAvailable,
  isEstimatedTimeAvailable,
  isPlannedTimeAvailable,
} from "./Event.helpers";
import * as S from "./Event.styles";
import EventDepartureStatusBadge from "./EventDepartureStatusBadge";

const isSmartEta = (event) => {
  return event?.flags?.includes(shipmentEventFlags.SMART_ESTIMATE);
};

const RowWrapper = ({ event }) => {
  const intl = useIntl();
  const estimatedText = isEstimatedTimeAvailable(event)
    ? `${getFormattedDateTime(intl)(get(event, "estimated"), true)}`
    : null;

  const plannedText = isPlannedTimeAvailable(event)
    ? getFormattedDateTime(intl)(get(event, "planned"), true)
    : null;
  const separator = estimatedText && plannedText ? " | " : null;

  return (
    <span>
      {estimatedText && (
        <>
          {" "}
          {intl.formatMessage(messages.dateEstimated)}: {estimatedText}
        </>
      )}
      {estimatedText !== plannedText && separator}
      {plannedText && estimatedText !== plannedText && (
        <>
          {intl.formatMessage(messages.dateFirstEstimate)}: {plannedText}
        </>
      )}
    </span>
  );
};

const Event = ({
  event,
  shouldContinue,
  latestUpdatedEvent,
  pathPointType,
  locationCode,
  inMapView,
  allEvents,
  isFutureEvent: isFutureEventLeftBorder,
  isQuoteAndBook,
}) => {
  const intl = useIntl();
  const reasonCode = event?.reasonCode;
  const eventIndex = useMemo(
    () =>
      allEvents.findIndex(
        (i) =>
          i.pathPointType === pathPointType &&
          i.locationCode === locationCode &&
          i.eventCode === event.code
      ),
    [allEvents, pathPointType, locationCode, event.code]
  );
  const someFutureEventHasActualTime = useMemo(() => {
    return allEvents
      .slice(eventIndex + 1)
      .filter(
        (allEventsEntry) =>
          !eventCodesNotFulfillingPreviousEvents.includes(
            allEventsEntry.eventCode
          )
      )
      .some((i) => i.hasEventActual);
  }, [allEvents, eventIndex]);
  const isFutureEvent = eventIndex > latestUpdatedEvent.index;
  const isNextEvent = eventIndex === latestUpdatedEvent.index + 1;
  let icon;
  if (someFutureEventHasActualTime) {
    icon = <CompletedEventIcon />;
  } else {
    if (get(event, "actual")) {
      icon = <CompletedEventIcon />;
    } else {
      if (isNextEvent) {
        icon = <NextEventIcon />;
      } else {
        icon = <FutureEventIcon />;
      }
    }
  }
  const eventDisplayLabel =
    event.customLabel ||
    (event.code
      ? intl.formatMessage({
          // pass object directly only for dynamic ids
          id: Prefixes.milestone + event.code,
          defaultMessage: event.code,
        })
      : "");

  const getEventDate = () => {
    if (event.customDate) {
      return event.customDate;
    }

    if (isActualTimeAvailable(event)) {
      return getFormattedDateTime(intl)(event.actual) + " | ";
    }

    return !isFutureEvent && someFutureEventHasActualTime
      ? `${intl.formatMessage(messages.timeNotAvailable)} | `
      : "";
  };

  return (
    <StepRow isInMapView={inMapView} isQuoteAndBook={isQuoteAndBook}>
      <S.IconCol isInMapView={inMapView} isQuoteAndBook={isQuoteAndBook}>
        <div>
          <SVGWrapper width={getRem(24)} height={getRem(24)}>
            {icon}
          </SVGWrapper>
        </div>
        {shouldContinue && (
          <LeftBorder
            isFutureEvent={isFutureEventLeftBorder}
            isQuoteAndBook={isQuoteAndBook}
          />
        )}
      </S.IconCol>
      <S.TextCol className="event-details" isInMapView={inMapView}>
        <Row>
          <Col>
            {reasonCode && reasonCode !== shipmentRouteEventCodeUnknown ? (
              <Text fontSize={16} fontWeight="bold" lineHeight={1.6}>
                <SVGWrapper
                  fill={colors.dhlRed}
                  hoverFill={colors.dhlRed}
                  style={{ marginRight: "10px" }}
                >
                  <ErrorIcon />
                </SVGWrapper>
                {isActualTimeAvailable(event)
                  ? getFormattedDateTime(intl)(get(event, "actual")) + " | "
                  : ""}
                {eventDisplayLabel ? `${eventDisplayLabel} - ` : ""}
                {intl.formatMessage({
                  // pass object directly only for dynamic ids
                  id: Prefixes.reason + reasonCode,
                  defaultMessage: reasonCode,
                })}
              </Text>
            ) : (
              <S.Title
                fontWeight={
                  event.customFontWeight || (isFutureEvent ? "normal" : "bold")
                }
                fontSize={isQuoteAndBook ? "1rem" : "0.875rem"}
              >
                {getEventDate()}
                {eventDisplayLabel || "--"}
              </S.Title>
            )}
          </Col>
        </Row>
        <S.SubText isInMapView={inMapView}>
          <RowWrapper event={event} />
          <EventDepartureStatusBadge event={event} />
          {isSmartEta(event) && <EtaPredictionIcon />}
        </S.SubText>
      </S.TextCol>
    </StepRow>
  );
};

Event.defaultProps = {
  event: {},
};
Event.propTypes = {
  event: PropTypes.object,
  shouldContinue: PropTypes.bool,
};

export default Event;
