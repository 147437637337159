import { PartyType } from "constants/bookWorkflow";
import { MapProviderDataType } from "constants/googleSuggestions";
import * as MDM from "constants/masterDataManagement";
import { FormApi } from "final-form";
import { transformCountryCode } from "helpers/transformCountryCode";
import { prepareAddressFromGoogleSuggestions } from "hooks/useAddressSuggestions";
import React from "react";
import SvgDuration from "react-lib/es/icons/SvgDuration";
import SvgLocationPin from "react-lib/es/icons/SvgLocationPin";
import SvgPleaseContact from "react-lib/es/icons/SvgPleaseContact";
import rawCountries from "react-lib/es/molecules/PhoneInput/countryData/rawCountires";
import { partyGroupFieldPrefix } from "routes/MakeABooking/BookingNewBilling/BookingNewBilling.constants";
import { addressBookFormFields } from "./AddressBook/AddressBook.constants";
import * as C from "./AddressForm.constants";

const Google = window?.google;
const autocompleteService =
  Google && new Google.maps.places.AutocompleteService();
export const isGoogleAPILoaded = () => !!window.google;
const geocoderService = Google && new Google.maps.Geocoder();

const getBaseFormFields = (fieldPrefix: string) => ({
  countryFieldName: `${fieldPrefix}.address.countryCode`,
  addressLine2: `${fieldPrefix}.address.addressLine2`,
  addressLine1: `${fieldPrefix}.address.addressLine2`,
  postalCode: `${fieldPrefix}.address.postalCode`,
  city: `${fieldPrefix}.address.city`,
  stateProvince: `${fieldPrefix}.address.stateProvince`,
  companyName: `${fieldPrefix}.companyName`,
  contactDetailsName: `${fieldPrefix}.contactDetails.name`,
  email: `${fieldPrefix}.contactDetails.email`,
  phone: `${fieldPrefix}.contactDetails.phone`,
  addressId: `${fieldPrefix}.address.addressBookId`,
  isCustomerVisible: `${fieldPrefix}.address.isCustomerVisible`,
});

export const generateFormFields = (
  isForAddressBook: boolean,
  fieldPrefix: string
) => {
  const { countryFieldName } = getBaseFormFields(fieldPrefix);
  return {
    countryFieldName: isForAddressBook
      ? addressBookFormFields.countryCode
      : countryFieldName,
    country: isForAddressBook
      ? addressBookFormFields.country
      : `${fieldPrefix}.address.country`,
    addressLine2: isForAddressBook
      ? addressBookFormFields.street
      : `${fieldPrefix}.address.addressLine2`,
    addressLine1: isForAddressBook
      ? addressBookFormFields.streetAddress
      : `${fieldPrefix}.address.addressLine1`,
    postalCode: isForAddressBook
      ? addressBookFormFields.zip
      : `${fieldPrefix}.address.postalCode`,
    city: isForAddressBook
      ? addressBookFormFields.city
      : `${fieldPrefix}.address.city`,
    stateProvince: isForAddressBook
      ? addressBookFormFields.states
      : `${fieldPrefix}.address.stateProvince`,
    preferredAddressInputType: isForAddressBook
      ? addressBookFormFields.preferredAddressInputType
      : `${fieldPrefix}.address.preferredAddressInputType`,
    companyName: isForAddressBook
      ? addressBookFormFields.companyName
      : `${fieldPrefix}.companyName`,
    contactDetails: isForAddressBook
      ? addressBookFormFields.contacts
      : `${fieldPrefix}.contactDetails`,
    contactDetailsName: isForAddressBook
      ? addressBookFormFields.contactName
      : `${fieldPrefix}.contactDetails.name`,
    email: `${fieldPrefix}.contactDetails.email`,
    contactId: `${fieldPrefix}.contactDetails.contactId`,
    phone: isForAddressBook
      ? addressBookFormFields.phoneNumber
      : `${fieldPrefix}.contactDetails.phone`,
    reference: isForAddressBook
      ? addressBookFormFields.reference
      : `${fieldPrefix}.address.reference`,
    contacts: addressBookFormFields.contacts,
    contactType: addressBookFormFields.contactType,
    addressId: isForAddressBook
      ? addressBookFormFields.addressId
      : `${fieldPrefix}.address.addressBookId`,
    modifiedAt: isForAddressBook
      ? addressBookFormFields.modifiedAt
      : `${fieldPrefix}.modifiedAt`,
    isCustomerVisible: `${fieldPrefix}.address.isCustomerVisible`,
  };
};

export const restrictCountryList = (
  originCountries: MDM.Country[],
  destinationCountries: MDM.Country[],
  billingCountries: MDM.Country[],
  unfilteredCountries: MDM.Country[],
  prefix: string
) => {
  switch (prefix) {
    case "shipper":
    case "pickup":
      return originCountries;

    case "delivery":
    case "consignee":
      return destinationCountries;

    case "party":
      return billingCountries;

    case "addressBook":
    case "notify":
      return unfilteredCountries;

    default:
      return [];
  }
};

export const getPhoneCountryPrefix = (country?: string): undefined | string => {
  if (country === undefined) {
    return undefined;
  }

  const lcCountry = country.toLowerCase();
  const data = rawCountries.find((data) => data[C.iso2code] === lcCountry);

  return data?.[C.internationalDialCode];
};

export const formUpdateOnCountryChange = ({
  form,
  fieldPrefix,
  newCountry,
  setInputValue,
  setSelectSearchValue,
  setAddressInputTypeField,
  isForAddressBook,
  isLocalityBasedAddress,
}: C.FormUpdateOnCountryChangeType) => {
  const {
    addressLine1,
    city,
    postalCode,
    stateProvince,
    countryFieldName,
    country,
    phone,
    companyName,
    contactDetailsName,
    email,
    addressId,
    isCustomerVisible,
    contactDetails,
  } = generateFormFields(isForAddressBook, fieldPrefix);
  setInputValue("");
  setSelectSearchValue({});
  const prevCountry = form.getFieldState(countryFieldName);
  const { code, countryCode, label, value } = newCountry;
  const newPhonePrefix = getPhoneCountryPrefix(transformCountryCode(code));

  form.batch(() => {
    const clearAndResetField = (field: string): void => {
      if (typeof form?.getFieldState(field)?.initial === "object") {
        form.change(field, []);
      } else {
        form.change(field, "");
      }
      form.resetFieldState(field);
    };

    if (isForAddressBook) {
      if (prevCountry?.value !== value) {
        form.change(country, { code, countryCode, label, value });
        clearAndResetField(addressLine1);
        clearAndResetField(city);
        clearAndResetField(stateProvince);
        form
          .getFieldState(contactDetails)
          ?.value?.forEach((_: unknown, index: number) => {
            clearAndResetField(
              `${contactDetails}[${index}].${addressBookFormFields.contactName}`
            );
            clearAndResetField(
              `${contactDetails}[${index}].${addressBookFormFields.email}`
            );
            clearAndResetField(
              `${contactDetails}[${index}].${addressBookFormFields.phoneNumber}`
            );
            clearAndResetField(
              `${contactDetails}[${index}].${addressBookFormFields.contactType}`
            );

            form.change(contactDetails, [
              {
                ...C.contactsObjInitialValue,
                phoneNumber: getPhoneCountryPrefix(value) || "",
              },
            ]);
          });
      }
    } else {
      form.change(countryFieldName, value);
      form.change(addressLine1, "");
      form.change(city, "");
      form.change(stateProvince, "");
      form.change(companyName, "");
      form.change(contactDetailsName, "");
      form.change(email, "");
      form.change(fieldPrefix + ".terminalCode", "");
      form.change(addressId, "");
      form.change(isCustomerVisible, false);
      form.change(phone, newPhonePrefix ?? "");
      form.resetFieldState(countryFieldName);
      form.resetFieldState(addressLine1);
      form.resetFieldState(city);
      form.resetFieldState(stateProvince);
      form.resetFieldState(companyName);
      form.resetFieldState(contactDetailsName);
      form.resetFieldState(email);
      form.resetFieldState(phone);
    }

    if (!isLocalityBasedAddress && prevCountry?.value !== value) {
      form.change(postalCode, "");
      form.resetFieldState(postalCode);
    }

    setAddressInputTypeField();
  });
};

const googleLookup = async (
  countryCode: string,
  item: google.maps.places.AutocompletePrediction
): Promise<MapProviderDataType> => {
  if (!geocoderService) {
    throw new Error("No results");
  }
  const { results } = await geocoderService.geocode({ placeId: item.place_id });

  if (!results?.length) {
    throw new Error("No results");
  }

  const geoVal = results[0];

  const constGBCountryCode = "GB";

  const isGBGoogleSearch = countryCode === constGBCountryCode;
  const { zipCode, addressLine, city, areaLevel1, areaLevel2 } =
    prepareAddressFromGoogleSuggestions(geoVal, item, countryCode);

  return {
    place_id: geoVal.place_id,
    zipCode,
    addressLine,
    label: geoVal?.formatted_address,
    label_native: item.description,
    city,
    stateProvince: isGBGoogleSearch ? areaLevel2 : areaLevel1,
  };
};

export const setAddressValues = (
  {
    addressLine,
    city,
    zipCode,
    stateProvince,
    contactName,
    contactEmail,
    contactPhone,
    addressId,
    isCustomerVisible,
    companyName,
    referenceId,
    modifiedAt,
    contactId,
  }: C.AddressValues,
  form: FormApi,
  fieldPrefix: string,
  isForAddressBook: boolean,
  isLocalityBasedAddress: boolean
) => {
  const {
    addressLine1,
    city: cityField,
    postalCode,
    stateProvince: stateProvinceField,
    addressId: addressIdField,
    isCustomerVisible: isCustomerVisibleField,
    contactDetailsName,
    email,
    phone,
    companyName: companyNameField,
    reference,
    modifiedAt: modifiedAtField,
    contactId: contactIdField,
  } = generateFormFields(isForAddressBook, fieldPrefix);

  form.batch(() => {
    form.change(addressLine1, addressLine);
    form.resetFieldState(addressLine1);

    form.change(cityField, city);
    form.resetFieldState(cityField);

    form.change(stateProvinceField, stateProvince);
    form.resetFieldState(stateProvinceField);

    if (zipCode && !isLocalityBasedAddress) {
      form.change(postalCode, zipCode);
      form.resetFieldState(postalCode);
    }

    if (contactName) {
      form.change(contactDetailsName, contactName);
    }

    if (contactEmail) {
      form.change(email, contactEmail);
    }

    if (contactPhone) {
      form.change(phone, contactPhone);
    }

    if (companyName) {
      form.change(companyNameField, companyName);
    }

    if (addressId) {
      form.registerField(addressIdField, () => {}, { value: true });
      form.change(addressIdField, String(addressId));
    } else {
      form.change(addressIdField, "");
    }

    if (referenceId) {
      form.registerField(reference, () => {}, { value: true });
    }

    if (modifiedAt && isForAddressBook) {
      form.registerField(modifiedAtField, () => {}, { value: true });
      form.change(modifiedAtField, modifiedAt);
    }

    if (isCustomerVisible) {
      form.change(isCustomerVisibleField, isCustomerVisible);
    } else {
      form.change(isCustomerVisibleField, false);
    }

    if (contactId) {
      form.change(contactIdField, contactId);
    }
  });
};

export const getGoogleOptions = async (
  keyword: string,
  countryCode: string
): Promise<C.SearchOptionType[]> => {
  const code = transformCountryCode(countryCode);
  const { predictions } = await autocompleteService.getPlacePredictions({
    input: keyword,
    componentRestrictions: { country: code },
  });

  return predictions.map((item) => ({
    label: item.description,
    value: item.place_id,
    lookup: () => googleLookup(code, item),
  }));
};

export const combineSearchInputOptions = (
  googleAddresses: C.SearchOptionType[],
  addressBookAddresses?: C.AddressBookAddressesResponse
) => {
  const addressBookAddressesOptions =
    addressBookAddresses?.getAddressBookAddresses?.customer
      ?.map((item) => ({
        label: `${item.company_name}, ${item.address}`,
        value: item.id,
        categoryId: C.AddressBookCategoryId,
        icon: <SvgPleaseContact />,
        categoryType: C.CategoryType.CUSTOMER,
        source: C.SourceType.ADDRESS_BOOK_ADDRESSES,
      }))
      .slice(0, C.CategoryOptionsLimit) || [];

  const recentlyUsedOptions =
    addressBookAddresses?.getAddressBookAddresses?.company
      ?.map((item) => ({
        label: `${item.company_name}, ${item.address}`,
        value: item.id,
        categoryId: C.AddressesCategoryId,
        icon: <SvgDuration />,
        categoryType: C.CategoryType.COMPANY,
        source: C.SourceType.RECENTLY_USED,
      }))
      .slice(0, C.CategoryOptionsLimit) || [];

  const googleAddressOptions = googleAddresses
    .map((item) => ({
      ...item,
      categoryId: C.AddressesCategoryId,
      icon: <SvgLocationPin />,
    }))
    .slice(0, C.CategoryOptionsLimit);

  return [
    ...recentlyUsedOptions,
    ...googleAddressOptions,
    ...addressBookAddressesOptions,
  ];
};

export const setContact = (
  contacts: C.AddressBookCustomerClient[],
  selectedId: number,
  form: FormApi,
  fieldPrefix: string,
  isForAddressBook: boolean
) => {
  const { contactDetailsName, email, phone, contactId } = generateFormFields(
    isForAddressBook,
    fieldPrefix
  );

  const selectedContact = contacts.find(
    (contact) => contact.contact_id === selectedId
  );

  if (selectedContact) {
    form.batch(() => {
      form.change(contactDetailsName, selectedContact.contact.contact_name);
      form.change(email, selectedContact.contact.email);
      form.change(phone, selectedContact.contact.phone);
      form.change(contactId, `${selectedContact.contact_id}`);
    });
  }
};

export const filterContactsBasedOnPartyType = (
  prefix: string,
  contacts: C.AddressBookAddressSearchCustomerContact[]
) => {
  switch (prefix.toUpperCase()) {
    case PartyType.BILLING:
    case partyGroupFieldPrefix:
      return contacts.filter((contact) => contact.is_billing);
    case PartyType.DELIVERY:
      return contacts.filter((contact) => contact.is_delivery);
    case PartyType.NOTIFY:
      return contacts.filter((contact) => contact.is_notify);
    case PartyType.PICKUP:
      return contacts.filter((contact) => contact.is_pickup);
    case PartyType.SHIPPER:
      return contacts.filter((contact) => contact.is_shipper);
    default:
      return contacts;
  }
};

export const prepareAddressData = (
  prefix: string,
  form: FormApi,
  contacts: C.AddressBookCustomerClient[],
  countryList: MDM.Country[],
  dataFromForm?: boolean,
  newEmptyContact?: boolean,
  addressEdit?: boolean
): C.AddressFormValues => {
  const {
    addressLine1,
    city,
    postalCode,
    stateProvince,
    countryFieldName,
    companyName,
    addressId,
    addressLine2,
    preferredAddressInputType,
    reference,
    contactDetailsName,
    phone,
    email,
    modifiedAt,
  } = generateFormFields(false, prefix);
  const country = countryList.find(
    (el) => el.countryCode === form.getFieldState(countryFieldName)?.value
  );

  form.registerField(addressId, () => {}, { value: true });
  const generateContacts = () => {
    if (dataFromForm) {
      return [
        {
          contactName: form.getFieldState(contactDetailsName)?.value,
          phoneNumber: form.getFieldState(phone)?.value,
          email: form.getFieldState(email)?.value,
          contactType: [],
        },
      ];
    } else {
      return contacts.map((contact) => ({
        contactName: contact.contact.contact_name,
        phoneNumber: contact.contact.phone,
        email: contact.contact.email,
        contactType: [
          ...(contact.contact.is_billing ? [PartyType.BILLING] : []),
          ...(contact.contact.is_consignee ? [PartyType.CONSIGNEE] : []),
          ...(contact.contact.is_delivery ? [PartyType.DELIVERY] : []),
          ...(contact.contact.is_pickup ? [PartyType.PICKUP] : []),
          ...(contact.contact.is_shipper ? [PartyType.SHIPPER] : []),
          ...(contact.contact.is_notify ? [PartyType.NOTIFY] : []),
        ],
      }));
    }
  };

  return {
    addressBook: {
      companyName: form.getFieldState(companyName)?.value,
      reference: form.getFieldState(reference)?.value,
      address: {
        ...(addressEdit && {
          modifiedAt: form.getFieldState(modifiedAt)?.value,
        }),
        country: {
          code: country?.code,
          countryCode: country?.countryCode,
          label: country?.label,
          value: country?.label,
        },
        streetAddress: form.getFieldState(addressLine1)?.value,
        street: form.getFieldState(addressLine2)?.value,
        zip: form.getFieldState(postalCode)?.value,
        city: form.getFieldState(city)?.value,
        states: form.getFieldState(stateProvince)?.value,
        preferredAddressInputType: form.getFieldState(preferredAddressInputType)
          ?.value,
        addressId: form.getFieldState(addressId)?.value,
      },
      contacts: [
        ...generateContacts(),
        ...(newEmptyContact
          ? [{ contactName: "", phoneNumber: "", email: "", contactType: [] }]
          : []),
      ],
    },
  };
};

export const resetAddressForm = ({
  form,
  fieldPrefix,
  setInputValue,
  setSelectSearchValue,
  isLocalityBasedAddress,
  omitCountryReset,
}: C.FormResetType) => {
  const {
    addressLine1,
    city,
    postalCode,
    stateProvince,
    countryFieldName,
    phone,
    companyName,
    contactDetailsName,
    email,
    addressId,
    isCustomerVisible,
  } = generateFormFields(false, fieldPrefix);
  setInputValue("");
  setSelectSearchValue({});

  form.batch(() => {
    if (!omitCountryReset) {
      form.resetFieldState(countryFieldName);
    }
    form.change(addressLine1, "");
    form.change(city, "");
    form.change(stateProvince, "");
    form.change(companyName, "");
    form.change(contactDetailsName, "");
    form.change(email, "");
    form.change(fieldPrefix + ".terminalCode", "");
    form.change(addressId, "");
    form.change(isCustomerVisible, false);
    form.change(phone, "");

    if (!isLocalityBasedAddress) {
      form.change(postalCode, "");
      form.resetFieldState(postalCode);
    }
  });
};
