import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { ShipmentDetailsInteractions } from "adobe-analytics/content-interaction-events/shipment-details/ShipmentDetailsEvents.constants";
import { messages } from "./ShipmentDetails.messages";

export const ShipmentDetailsPathPointLocationType = {
  CITY: "CITY",
  PORT: "PORT",
  TRANSSHIPMENT_PORT: "TRANSSHIPMENT_PORT",
  AIRPORT: "AIRPORT",
  RAILSTATION: "RAILSTATION",
  ROADTERMINAL: "ROADTERMINAL",
  STATION: "STATION",
  VESSEL: "VESSEL",
  AIRCRAFT: "AIRCRAFT",
  TRAIN: "TRAIN",
  TRUCK: "TRUCK",
};

export const ShipmentDetailsTabsEnum = {
  ROUTE: "route",
  DETAILS: "details",
  DOCUMENTS: "documents",
};

export const SourceSystemNamesEnum = {
  CW: "CW",
  MG: "MG",
  ACT: "ACT",
};

export const shipmentDetailsTabs = [
  {
    name: ShipmentDetailsTabsEnum.ROUTE,
    route: "",
    message: messages.route,
    trackCode: {
      inMapView: 86,
      outMapView: 33,
    },
    onClick: () => dispatchInteractionEvent(ShipmentDetailsInteractions.ROUTE),
  },
  {
    name: ShipmentDetailsTabsEnum.DETAILS,
    route: "/details",
    message: messages.details,
    trackCode: {
      inMapView: 87,
      outMapView: 34,
    },
    onClick: () =>
      dispatchInteractionEvent(ShipmentDetailsInteractions.DETAILS),
  },
  {
    name: ShipmentDetailsTabsEnum.DOCUMENTS,
    route: "/documents",
    message: messages.documents,
    trackCode: {
      inMapView: 88,
      outMapView: 35,
    },
    onClick: () =>
      dispatchInteractionEvent(ShipmentDetailsInteractions.DOCUMENTS),
  },
];

export const shipmentRouteEventCodeUnknown = "UNKW";
export const documentsRole = "myDHLi_Documents_Documents";
export const documentsUploadRole = "myDHLi_Documents_Upload";

export const ShipmentsDetailsRootPaths = {
  MAP_SHIPMENTS: "/map/shipments/",
  SHIPMENTS_DETAILS: "/shipment-details/",
  SHIPMENTS: "/shipments",
};
