import { colors } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled, { css } from "styled-components";

export const Address = styled.div<{ isQuoteAndBook: boolean }>`
  font-size: 1rem;
  line-height: 1.25;
  color: ${colors.black};

  ${({ isQuoteAndBook }) =>
    isQuoteAndBook &&
    css`
      font-size: 0.875rem;
      line-height: 1.29;
      color: #191919;
    `}
`;

export const PathPointLogoCircle = styled.div<{
  isQuoteAndBook: boolean;
  isMobile: boolean;
}>`
  width: 2rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 50%;
  padding: 0.1875rem;
  margin-left: 0.25rem;
  background: ${colors.white};

  ${({ isQuoteAndBook, isMobile }) =>
    isQuoteAndBook &&
    css`
      width: ${isMobile ? "2rem" : "2.5rem"};
      height: ${isMobile ? "2rem" : "2.5rem"};
      padding: ${isMobile ? `0.125rem 0 0 0.3125rem` : "0.3125rem"};
    `};
`;

export const CodeLabel = styled.span`
  font-weight: 300;
  display: inline-block;
  text-transform: uppercase;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    line-height: 3.125rem;
  }
`;

export const Title = styled.h3<{ shouldBeSmall: boolean }>`
  font-size: 1.25rem;
  font-stretch: condensed;
  font-weight: 900;
  margin: 0;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    ${({ shouldBeSmall }) =>
      shouldBeSmall
        ? null
        : css`
            font-size: 1.75rem;
            line-height: inherit;
          `};
  }
`;

export const IconCol = styled.div<{ isInMapView: boolean }>`
  flex: 0 0 3.75rem;
  max-width: 3.75rem;
  padding-right: 1.25rem;
  position: relative;
  ${({ isInMapView }) =>
    isInMapView
      ? css`
          flex: 0 0 3.125rem;
          max-width: 3.125rem;
          padding-right: 0.625rem;
        `
      : ``};

  @media (max-width: ${SCREEN_SIZES.until.md}) {
    flex: 0 0 3.125rem;
    max-width: 3.125rem;
    padding-right: 0.625rem;
  }
`;

export const TextCol = styled.div<{
  isInMapView: boolean;
  isMobile: boolean;
  isQuoteAndBook: boolean;
}>`
  ${({ isInMapView, isMobile }) =>
    !isMobile && isInMapView
      ? css`
          padding-top: 0.25rem;
        `
      : null}
  flex: 1;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    padding-right: ${({ isQuoteAndBook, isInMapView }) =>
      isInMapView || isQuoteAndBook ? 0 : "3.75rem"};
  }
`;
