import styled from "styled-components";
import { getRem } from "../../../../utils/css";

const ProgressTimeStatus = styled.div`
  font-size: ${getRem(16)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(p) =>
    p.isCompleted ? p.theme.colors.black : p.theme.colors.dhlRed};
  text-transform: uppercase;
  margin-bottom: ${getRem(4)};
`;

export default ProgressTimeStatus;
