import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { MapTrackingInteractions } from "adobe-analytics/content-interaction-events/map-tracking/MapTrackingEvents.constants";
import { ShipmentDetailsInteractions } from "adobe-analytics/content-interaction-events/shipment-details/ShipmentDetailsEvents.constants";
import PropTypes from "prop-types";
import React from "react";
import { ShipmentsDetailsRootPaths } from "../ShipmentDetails.constants";
import { formatLocation } from "./ShipmentTitleBar.helpers";
import * as S from "./ShipmentTitleBar.styles";

const ShipmentTitleBar = ({ shipmentData, inMapView, rootPath }) => (
  <S.Wrapper inMapView={inMapView}>
    <S.Icon type={shipmentData.transportMode} isCDZ={!!shipmentData.cdzFlag} />
    <S.LocationText>{formatLocation(shipmentData?.origin)}</S.LocationText>
    <S.ForwardIcon />
    <S.LocationText>{formatLocation(shipmentData?.destination)}</S.LocationText>

    <S.FavoriteButton
      shipment={shipmentData}
      trackId={inMapView ? 81 : 30}
      onClick={() => {
        if (rootPath === ShipmentsDetailsRootPaths.SHIPMENTS) {
          dispatchInteractionEvent(
            shipmentData.isFavorite
              ? ShipmentDetailsInteractions.UNFOLLOWING_SHIPMENT
              : ShipmentDetailsInteractions.FOLLOWING_SHIPMENT
          );
        } else if (rootPath === ShipmentsDetailsRootPaths.MAP_SHIPMENTS) {
          shipmentData.isFavorite
            ? dispatchInteractionEvent(
                MapTrackingInteractions.UNFOLLOWING_SHIPMENT
              )
            : dispatchInteractionEvent(
                MapTrackingInteractions.FOLLOWING_SHIPMENT
              );
        } else if (rootPath === ShipmentsDetailsRootPaths.SHIPMENTS_DETAILS) {
          shipmentData.isFavorite
            ? dispatchInteractionEvent(
                ShipmentDetailsInteractions.UNFOLLOWING_SHIPMENT
              )
            : dispatchInteractionEvent(
                ShipmentDetailsInteractions.FOLLOWING_SHIPMENT
              );
        }
      }}
    />
  </S.Wrapper>
);

ShipmentTitleBar.propTypes = {
  text: PropTypes.object,
  currentRoute: PropTypes.instanceOf(Object),
  isFavorite: PropTypes.bool,
  shipmentData: PropTypes.instanceOf(Object),
  rootPath: PropTypes.string,
};
ShipmentTitleBar.defaultProps = {
  text: {},
  currentRoute: {},
  isFavorite: false,
  shipmentData: {},
};

export default ShipmentTitleBar;
