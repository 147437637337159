import Row from "components/atoms/Row";
import { colors } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";

export const StepRow = styled(Row)<{
  isQuoteAndBook: boolean;
  isInMapView: boolean;
  isFirstOrLast?: boolean;
}>`
  padding-bottom: ${({ isQuoteAndBook }) => (isQuoteAndBook ? 0 : "1rem")};

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    padding-bottom: ${({ isQuoteAndBook, isFirstOrLast, isInMapView }) =>
      isFirstOrLast || isQuoteAndBook ? 0 : isInMapView ? "1rem" : "1.25rem"};
  }
`;

export const LeftBorder = styled.span<{
  isQuoteAndBook: boolean;
  isFutureEvent: boolean;
}>`
  position: absolute;
  width: 0.125rem;
  top: 0.125rem;
  bottom: -1rem;
  left: 1.1875rem;
  border-left: ${({ isFutureEvent }) =>
    isFutureEvent
      ? `3px solid ${colors.gray30}`
      : `3px solid ${colors.gray80}`};
  z-index: -1;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    left: ${({ isQuoteAndBook }) =>
      isQuoteAndBook ? "1.3125rem" : "1.1875rem"};
    bottom: -1.25rem;
  }
`;
