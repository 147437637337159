import PropTypes from "prop-types";
import React from "react";

import { Label, Wrapper } from "./styles";

const DataLabelWithHelp = (props) => {
  return (
    <Wrapper padding="0">
      <Label style={{ fontSize: "14px" }}>{props.label}</Label>
    </Wrapper>
  );
};
DataLabelWithHelp.defaultProps = {
  label: "No Label",
};
DataLabelWithHelp.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
};

export default DataLabelWithHelp;
