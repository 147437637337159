import { defineMessages } from "react-intl";

export const messages = defineMessages({
  carrierName: {
    id: "shipment.detail.carrierName",
    defaultMessage: "Carrier",
  },
  shipperName: {
    id: "shipment.detail.shipperName",
    defaultMessage: "Shipper",
  },
  consigneeName: {
    id: "shipment.detail.consigneeName",
    defaultMessage: "Consignee",
  },
  customerName: {
    id: "shipment.detail.customerName",
    defaultMessage: "Customer",
  },
  customerReference: {
    id: "shipment.detail.customerReference",
    defaultMessage: "Your Reference",
  },
  masterBills: {
    id: "shipment.detail.masterBills",
    defaultMessage: "Master Bill",
  },
  customsEntryNumber: {
    id: "shipment.detail.customsEntryNumber",
    defaultMessage: "Customs Entry",
  },
  flightNumbers: {
    id: "shipment.detail.flightNumbers",
    defaultMessage: "Flight/Voyage",
  },
  vesselNames: {
    id: "shipment.detail.vesselNames",
    defaultMessage: "Vessel",
  },
  containerNumber: {
    id: "shipment.detail.containerNumber",
    defaultMessage: "Container",
  },
  containerSizes: {
    id: "shipment.detail.containerSizes",
    defaultMessage: "Container Size",
  },
  goodsDescriptions: {
    id: "shipment.detail.goodsDescriptions",
    defaultMessage: "Goods Description",
  },
  actualWeight: {
    id: "shipment.detail.actualWeight",
    defaultMessage: "Total Weight",
  },
  volume: {
    id: "shipment.detail.volume",
    defaultMessage: "Volume",
  },
  chargeableWeight: {
    id: "shipment.detail.chargeableWeight",
    defaultMessage: "Chargeable Weight",
  },
  pieces: {
    id: "shipment.detail.pieces",
    defaultMessage: "Pieces",
  },
  incoTerms: {
    id: "shipment.detail.incoTerms",
    defaultMessage: "Incoterms",
  },
  packageID: {
    id: "shipment.detail.packageID",
    defaultMessage: "Package ID",
  },
  loadingMeters: {
    id: "shipment.detail.loadingMeters",
    defaultMessage: "Loading Meters",
  },
  featureNames: {
    id: "shipment.detail.featureNames",
    defaultMessage: "Feature Names",
  },
  productName: {
    id: "shipment.detail.productName",
    defaultMessage: "Product Name",
  },
  orderCodeLabel: {
    id: "shipment.detail.tab.orderCodeLabel",
    defaultMessage: "Order Code",
  },
  co2WtWFootprint: {
    id: "shipment.detail.co2WtWFootprint",
    defaultMessage: "Total Carbon Footprint",
  },
  co2WtWFootprintDescription: {
    id: "shipment.detail.co2WtWFootprint.tooltip.description",
    defaultMessage:
      "CO₂ equivalents Well-to-Wheel is a holistic measure of greenhouse gas effects. It includes all six greenhouse gases expressed in their equivalent amount of CO₂, measured over the whole lifecycle of the fuel, including production, transportation and combustion.",
  },
  co2WtWEfficiency: {
    id: "shipment.detail.co2WtWEfficiency",
    defaultMessage: "Main Haul Efficiency",
  },
  co2WtWEfficiencyDescription: {
    id: "shipment.detail.co2WtWEfficiency.tooltip.description",
    defaultMessage:
      "Carbon efficiency (CO₂e footprint per ton-kilometer) helps to understand the emission intensity of a specific type of transport and facilitates comparisons across time, transport mode, product or other units.",
  },
  co2WtWEfficiencyUnit: {
    id: "shipment.detail.co2WtWEfficiencyUnit",
    defaultMessage: "g CO₂e per TKM",
  },
  co2WtWFootprintUnit: {
    id: "shipment.detail.co2WtWFootprintUnit",
    defaultMessage: "t CO₂e",
  },
});
