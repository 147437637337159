import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { FAVORITE_ITEM, UNFAVORITE_ITEM } from "queries/AAA/favoriteQueries";
import {
  ShipmentDetailsFavoriteFragment,
  ShipmentFavoriteFragment,
} from "queries/Track/shipmentQueries";
import React from "react";
import { StarFilled, StarOutlined } from "./ShipmentFavoriteButton.styles";

const fragments = [ShipmentDetailsFavoriteFragment, ShipmentFavoriteFragment];
const typeNames = ["ShipmentData", "ShipmentDetails"];
const updateApolloCache = (cache, { data }, { context }) => {
  const { shipmentId } = context.shipment;
  let isFavorite = null;
  if (data?.favorite === true) {
    isFavorite = true;
  } else if (data?.unFavorite === true) {
    isFavorite = false;
  }
  if (isFavorite !== null) {
    for (const __typename of typeNames) {
      const id = cache.identify({ __typename, shipmentId });
      for (const fragment of fragments) {
        cache.writeFragment({ id, fragment, data: { isFavorite } });
      }
    }
  }
};

const ShipmentFavoriteButton = ({
  className,
  shipment,
  trackId,
  onClick,
  dispatchInteractionEvent,
}) => {
  const requestOptions = {
    errorPolicy: "all",
    // This gives a console warning, but works as expected, issue was already raised in the apollo repo:
    // https://github.com/apollographql/apollo-client/issues/5419
    refetchQueries: [
      "GetFavoriteShipmentListQuery",
      "FavoriteShipmentCountQuery",
    ],
    context: { shipment },
    update: updateApolloCache,
    variables: {
      item: {
        key: shipment.shipmentId,
        type: "SHIPMENT",
      },
    },
    optimisticResponse: {
      unFavorite: shipment.isFavorite ? true : undefined,
      favorite: shipment.isFavorite ? undefined : true,
    },
  };
  const [favoriteItem, { loading: isFavoriting }] = useMutation(
    FAVORITE_ITEM,
    requestOptions
  );
  const [unFavoriteItem, { loading: isUnFavoriting }] = useMutation(
    UNFAVORITE_ITEM,
    requestOptions
  );
  const handleClick = (e) => {
    e.stopPropagation();
    if (!isFavoriting && !isUnFavoriting) {
      const mutate = shipment.isFavorite ? unFavoriteItem : favoriteItem;
      mutate().catch(() => {
        // error is handled in errorLink
      });
    }
    dispatchInteractionEvent?.(shipment.isFavorite);
    onClick?.();
  };

  const Icon = shipment.isFavorite ? StarFilled : StarOutlined;

  return (
    <Icon className={className} data-track={trackId} onClick={handleClick} />
  );
};

ShipmentFavoriteButton.propTypes = {
  className: PropTypes.string,
  shipment: PropTypes.shape({
    shipmentId: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool.isRequired,
  }).isRequired,
  trackId: PropTypes.number,
  onClick: PropTypes.func,
  dispatchInteractionEvent: PropTypes.func,
};

export default ShipmentFavoriteButton;
