export const errorPopupItems = [
  "favoriteItem",
  "unFavoriteItem",
  // "MarkAsViewed",
  "getMenuItems",
  "getAvailableWidgets",
  "GetCorporatePartnersQuery",
  "ExcelExportLinkQuery",
  // "LastViewedShipmentListQuery",
  "GetPreferenceItemQuery",
  "GetNotificationConfigByTypeQuery",
  "GetNotificationByIdQuery",
  "GetQuotesListQuery",
  "CreateCustomNotificationMutation",
  "UpdateNotificationMutation",
  "GetShipmentDocuments",
  "CurrencyListQuery",
  "GetNotificationsQuery",
  // "CurrentLocationsQuery",
  "SetPreferenceItemQuery",
  // "ShareQuoteMutation",
  // "ShareShipmentMutation",
  "getUserDetailsQuery",
  // "updateUserDetails",
  "ShipmentListQuery",
  // "getAnalyticsItems",
  // "ColumnSuggestionQuery",
  "SearchSuggestionQuery",
  "updateUserDetails",
  "DeleteCustomNotificationMutation",
  "AddNewAddressToUsersAddressBook",
  "EditAddressToUsersAddressBookMutation",
  "DeleteAddressFromUsersAddressBookMutation",
  "AddNewAddressContact",
  "EditAddressContact",
  "DeleteAddressContact",
  "booking",
  "createDraft",
  "getContainersDescriptions",
  "updateRoute",
  "updateCargo",
  "updateServices",
  "updateBilling",
  "finalizeBooking",
  "submitBooking",
  "createBooking",
  "deleteBookingDraft",
  "ShareBookingMutation",
  "GetServiceLanguages",
  "GetDangerousGoodsCommodityGroups",
  "GetDangerousGoodsDivisions",
  "GetDangerousGoodsClasses",
  "GetDangerousGoodsPackagingGroups",
  "GetCountries",
  "GetTerminals",
  "GetCountriesProducts",
  "GetAemByLanguageAndCountry",
  "GetTermsOfUseQuery",
  "GetUserRolesQuery",
  "GetProductsQuery",
  "GetReferenceTypesQuery",
  "GetDocumentTypesQuery",
  "GetPackagesTypesQuery",
  "GetDocumentsUploaded",
  "GetBookingsList",
  "GetConfigs",
  "GetServicesByProductAndCountries",
  "GetIncotermsByProductAndType",
  "GetBillingTypesByIncoterm",
  "UserAddressBookQuery",
  "UserAddressBookFiltersQuery",
  "ExportBookingList",
  "GetOrders",
  "GetOrderById",
  "GetOrderLines",
  "GetOrderEventsById",
  "getAddressBookAddresses",
  "CreateNewAddressBookCustomerAddress",
  "EditAddressBookCustomerAddress",
  "DeleteAddressBookCustomerAddress",
  "GetShipmentsList",
  "GetShipmentDetailsById",
  "GetShipmentDetailsCustomsById",
  "GetShipmentDetailsMapDataById",
];

export const errorPopupItemsQnB = [
  "GetQuoteOptions",
  "GetBookingPickupDatesQuery",
  "GetTermsAndConditionsQuery",
  "GetRecalculatedQuoteOption",
  "GetQuotesListQuery",
  "QuoteLocalitiesListQuery",
  "QuoteCountriesListQuery",
  "getPrivateCustomerDomains",
  "GetIncoTermsQuery",
  "SaveQuoteOffer",
  "QuotePackageTypesQuery",
  "QuotePackageTypeInfoQuery",
  "QuoteInsuranceLabel",
  "BioFuelTermsAndConditions",
  // "QuoteContractIdVerificationQuery",
  "GetTermsAndConditionsQuery",
  "CommoditiesListQuery",
  "GetConsignment",
  "UpdateConsignment",
  "getProductMaxDimensions",
  "CalculateConsignment",
  "CreateConsignment",
];

export const errorPopupItemsReporting = [
  "ScheduleReport",
  "ReportingViewExport",
  "ReportingSaveView",
  "GetReportingSharedViews",
  "GetReportingView",
  "GetReportingTemplates",
  "GetMyReports",
  "GetReportingSchedule",
  "GetReportArchive",
  "ReportingDeleteView",
  "ReportingDeleteSchedule",
  "ReportingDeleteReportRecipient",
  "CreateReport",
  "onUploadFailure",
];

export const errorPopupItemsCritical = [
  "getMenuItems",
  "GetQuotesListQuery",
  "QuotePackageTypesQuery",
  "QuotePackageTypeInfoQuery",
  "GetCorporatePartnersQuery",
  "ShipmentListQuery",
  "getAvailableWidgets",
];
