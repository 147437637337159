import { defineMessages } from "react-intl";

export default defineMessages({
  addressSectionTitle: {
    id: "bookingStandalone.addressBook.addressSectionTitle",
    defaultMessage: "Address",
  },

  companyFieldLabel: {
    id: "bookingStandalone.addressBook.companyFieldLabel",
    defaultMessage: "Company",
  },

  addressLine1Label: {
    id: "bookingStandalone.addressForm.addressLine1Label",
    defaultMessage: "Street, building, apartment, etc.",
  },

  zipLabel: {
    id: "bookingStandalone.addressForm.zipLabel",
    defaultMessage: "ZIP",
  },

  cityLabel: {
    id: "bookingStandalone.addressForm.cityLabel",
    defaultMessage: "City",
  },

  countryLabel: {
    id: "bookingStandalone.addressForm.countryLabel",
    defaultMessage: "Country / Region",
  },

  stateLabel: {
    id: "bookingStandalone.addressForm.stateLabel",
    defaultMessage: "State/ Province/ County (optional)",
  },

  referenceLabel: {
    id: "bookingStandalone.addressForm.referenceLabel",
    defaultMessage: "Reference ID (optional)",
  },
  selectCountryFirstHint: {
    id: "bookingStandalone.streetAddress.selectCountry.hint",
    defaultMessage:
      "Please fill out Country/Region first to update the other fields",
  },
  selectResult: {
    id: "bookingStandalone.addressForm.selectResult",
    defaultMessage: "Select result",
  },
  noResults: {
    id: "bookingStandalone.addressForm.noResults",
    defaultMessage: "No results",
  },
  searchPlaceholder: {
    id: "bookingStandalone.addressForm.searchPlaceholder",
    defaultMessage: "Start typing street address...",
  },
});
