export const transportModeFilters = {
  AIR: "AIR",
  OCEAN: "OCEAN",
  RAIL: "RAIL",
  ROAD: "ROAD",
  IW: "IW",
};

export const fullMapBounds = {
  south: -62.679670138149405,
  west: -99.171875,
  north: 47.03197592759684,
  east: 153.953125,
};

export const singleShipmentDetailsInitialState = {
  containerList: [],
  containerFlyOutList: [],
  containerNumbers: [],
};

export const ShipmentPathPointCurrentLocation = "CURRENT_LOCATION";
