const timeDiffToTZAbbr = (str) => {
  return str
    .replace("-08:00", "PST")
    .replace("-06:00", "CST")
    .replace("-05:00", "EST")
    .replace("-04:00", "AST")
    .replace("-03:00", "ADT")
    .replace("+00:00", "UTC")
    .replace("+01:00", "CET")
    .replace("+02:00", "EET")
    .replace("+03:00", "MSK");
};

export default timeDiffToTZAbbr;
