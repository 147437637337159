import { useMutation } from "@apollo/client";
import { FormApi } from "final-form";
import safeJsonParse from "helpers/safeJsonParse";
import { RESET_PASSWORD } from "queries/AAA/userQueries";
import React, { useRef, useState, useEffect } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { useIntl } from "react-intl";
import { Modal, ButtonVariant, Input } from "react-lib";
import { Sizes, ValidationVariants } from "react-lib/es/atoms/input/constants";
import { useHistory } from "react-router-dom";
import * as C from "./ChangePasswordModal.constants";
import * as H from "./ChangePasswordModal.helpers";
import { messages } from "./ChangePasswordModal.messages";
import * as S from "./ChangePasswordModal.styles";

const ChangePasswordModal: React.FC<C.ChangePasswordModalProps> = ({
  isModalOpen,
  onModalClose,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const formRef = useRef<FormApi<C.ChangePasswordFormValues>>();

  const [isFormValid, setIsFormValid] = useState(false);
  const [apiError, setApiError] = useState<C.PasswordUpdateErrorEnum>();
  const formValidator = H.createFormValidator(intl, apiError);

  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      history.push("/logout?confirmLogoutSkip=1");
    },
  });

  const onInputChange =
    (
      onChange: (value: string) => void,
      inputName: C.ChangePasswordFormFieldName
    ) =>
    (value: string) => {
      if (inputName === C.ChangePasswordFormFieldName.CURRENT_PASSWORD) {
        if (apiError === C.PasswordUpdateErrorEnum.INVALID_CURRENT_PASSWORD) {
          setApiError(undefined);
        }
      } else if (apiError === C.PasswordUpdateErrorEnum.COMMON_WORD) {
        setApiError(undefined);
      }

      onChange(value);
    };

  const onSubmit = (values: C.ChangePasswordFormValues) => {
    if (!loading) {
      resetPassword({
        variables: {
          oldPassword: values.currentPassword,
          password: values.newPassword,
        },
      });
    }
  };

  const onClose = () => {
    if (!loading) {
      onModalClose();
    }
  };

  useEffect(() => {
    const errorResponse = error?.graphQLErrors?.[0]?.extensions
      ?.response as any;
    const errorCode = errorResponse?.status;
    const errorBody = safeJsonParse(errorResponse?.body);

    if (errorBody?.error === C.PasswordUpdateErrorEnum.COMMON_WORD) {
      setApiError(C.PasswordUpdateErrorEnum.COMMON_WORD);
    } else if (errorCode === 401) {
      setApiError(C.PasswordUpdateErrorEnum.INVALID_CURRENT_PASSWORD);
    }
  }, [error]);

  return (
    <Modal
      closeOnHeader
      noCancelButton
      show={isModalOpen}
      onHide={onClose}
      headline={intl.formatMessage(messages.changePassword)}
      buttons={
        <S.ButtonsWrapper>
          <S.StyledButton onClick={onClose} variant={ButtonVariant.SECONDARY}>
            {intl.formatMessage(messages.close)}
          </S.StyledButton>
          <S.StyledButton
            disabled={!isFormValid}
            onClick={() => formRef.current?.submit()}
          >
            {intl.formatMessage(messages.confirm)}
          </S.StyledButton>
        </S.ButtonsWrapper>
      }
    >
      <Form onSubmit={onSubmit} validate={formValidator}>
        {({ form, touched, values }) => {
          formRef.current = form;
          return (
            <S.StyledForm>
              <Field name={C.ChangePasswordFormFieldName.CURRENT_PASSWORD}>
                {({ input, meta }) => (
                  <S.InputWrapper>
                    <S.InputLabel>
                      {intl.formatMessage(messages.enterCurrentPassowrd)}
                    </S.InputLabel>
                    <Input
                      label={intl.formatMessage(messages.currentPassword)}
                      size={Sizes.LARGE}
                      hasError={meta.touched && meta.error}
                      inputProps={{
                        ...input,
                        type: "password",
                        onChange: onInputChange(
                          input.onChange,
                          C.ChangePasswordFormFieldName.CURRENT_PASSWORD
                        ),
                      }}
                      hint={meta.touched && meta.error ? meta.error : undefined}
                      validationVariant={
                        meta.touched && meta.error
                          ? ValidationVariants.INVALID
                          : undefined
                      }
                    />
                  </S.InputWrapper>
                )}
              </Field>
              <Field name={C.ChangePasswordFormFieldName.NEW_PASSWORD}>
                {({ input, meta }) => (
                  <S.InputWrapper>
                    <S.InputLabel>
                      {intl.formatMessage(
                        messages.enterNewPasswordAndConfirmIt
                      )}
                    </S.InputLabel>
                    <Input
                      label={intl.formatMessage(messages.newPassword)}
                      size={Sizes.LARGE}
                      hasError={
                        !input.value
                          ? meta.touched && meta.error
                          : values?.confirmPassword &&
                            touched?.confirmPassword &&
                            meta.touched &&
                            meta.error
                      }
                      inputProps={{
                        ...input,
                        type: "password",
                        onChange: onInputChange(
                          input.onChange,
                          C.ChangePasswordFormFieldName.NEW_PASSWORD
                        ),
                      }}
                      hint={
                        !input.value && meta.touched && meta.error
                          ? meta.error
                          : undefined
                      }
                      validationVariant={
                        (!input.value && meta.touched && meta.error) ||
                        (input.value &&
                          meta.touched &&
                          values?.confirmPassword &&
                          touched?.confirmPassword &&
                          meta.error)
                          ? ValidationVariants.INVALID
                          : undefined
                      }
                    />
                  </S.InputWrapper>
                )}
              </Field>
              <Field name={C.ChangePasswordFormFieldName.CONFIRM_PASSWORD}>
                {({ input, meta }) => (
                  <Input
                    label={intl.formatMessage(messages.confirmPassword)}
                    size={Sizes.LARGE}
                    hasError={
                      !input.value
                        ? meta.touched && meta.error
                        : values?.newPassword &&
                          touched?.newPassword &&
                          meta.error
                    }
                    inputProps={{
                      ...input,
                      type: "password",
                      onChange: onInputChange(
                        input.onChange,
                        C.ChangePasswordFormFieldName.CONFIRM_PASSWORD
                      ),
                    }}
                    hint={
                      (!input.value && meta.touched && meta.error) ||
                      (input.value &&
                        meta.touched &&
                        values?.newPassword &&
                        touched?.newPassword &&
                        meta.error)
                        ? meta.error
                        : undefined
                    }
                    validationVariant={
                      (!input.value && meta.touched && meta.error) ||
                      (input.value &&
                        meta.touched &&
                        values?.newPassword &&
                        touched?.newPassword &&
                        meta.error)
                        ? ValidationVariants.INVALID
                        : undefined
                    }
                  />
                )}
              </Field>
              <FormSpy
                subscription={{ valid: true }}
                onChange={({ valid }) => setIsFormValid(valid)}
              />
            </S.StyledForm>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
