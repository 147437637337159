import cloneDeep from "lodash/cloneDeep";
import findLast from "lodash/findLast";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";
import { ShipmentPathPointCurrentLocation } from "../../../routes/Dashboard/Dashboard.constants";
import RouteStep from "./RouteStep";

const StepsWrapper = styled.div`
  padding: ${(p) => (p.print ? 0 : getRem(48))} 0;
  @media print {
    padding: 0;
  }
  ${(p) =>
    p.isQuoteAndBook &&
    css`
      flex: 1;
      padding: 0;
    `};
`;

const RouteSteps = ({
  pathPoints: pathPointsProp,
  inMapView,
  transportMode,
  print,
  shipmentData,
  isQuoteAndBook,
}) => {
  const currentLocationPathIndex = pathPointsProp.findIndex(
    (p) => p.isCurrentLocation
  );

  const isShipmentTravelingIndex = pathPointsProp.findIndex(
    (p) => p.typeCode === ShipmentPathPointCurrentLocation
  );

  let pathPointsCopy;
  if (isShipmentTravelingIndex !== -1) {
    pathPointsCopy = pathPointsProp.map((p, i) => {
      const newPoint = cloneDeep(p);
      if (i === isShipmentTravelingIndex - 1) {
        newPoint.isFutureEventSeparator = true;
        newPoint.events[newPoint.events.length - 1].isFutureEvent = true;
      }
      if (i >= isShipmentTravelingIndex) {
        newPoint.isFutureEventTitle = true;
        newPoint.isFutureEventSeparator = true;
        newPoint.events.forEach((e) => (e.isFutureEvent = true));
      }
      return newPoint;
    });
  } else {
    pathPointsCopy = pathPointsProp.map((p, i) => {
      const newPoint = cloneDeep(p);
      if (i === currentLocationPathIndex) {
        newPoint.isFutureEventSeparator = true;
        newPoint.events[newPoint.events.length - 1].isFutureEvent = true;
      }
      if (i > currentLocationPathIndex) {
        newPoint.isFutureEventTitle = true;
        newPoint.isFutureEventSeparator = true;
        newPoint.events.forEach((e) => (e.isFutureEvent = true));
      }
      return newPoint;
    });
  }

  const pathPoints = pathPointsCopy.filter(
    (p) => p.typeCode !== ShipmentPathPointCurrentLocation
  );

  const { latestUpdatedEvent, allEvents } = useMemo(() => {
    let eventIndex = 0;
    const allEvents = pathPoints.reduce((prev, next) => {
      (next.events || []).forEach((e) => {
        prev.push({
          hasEventActual: !!get(e, "actual"),
          eventCode: e.code,
          pathPointType: next.typeCode,
          locationCode: get(next, "location.unLoCode", ""),
          index: eventIndex++,
        });
      });
      return prev;
    }, []);
    return {
      latestUpdatedEvent: findLast(allEvents, (e) => e.hasEventActual) || {},
      allEvents,
    };
  }, [pathPoints]);
  return (
    <StepsWrapper print={print} isQuoteAndBook={isQuoteAndBook}>
      {pathPoints.map((p, i) => (
        <RouteStep
          key={`${p.typeCode}:${get(p, "location.unLoCode", "")}`}
          pathPoint={p}
          separator={i < pathPoints.length - 1}
          latestUpdatedEvent={latestUpdatedEvent}
          inMapView={inMapView}
          transportMode={transportMode}
          shipmentData={shipmentData}
          isLast={pathPoints.length === i + 1}
          allEvents={allEvents}
          isFutureEventSeparator={p.isFutureEventSeparator}
          isFutureEventTitle={p.isFutureEventTitle}
          isFirst={i === 0}
          isQuoteAndBook={isQuoteAndBook}
        />
      ))}
    </StepsWrapper>
  );
};
RouteSteps.propTypes = {
  pathPoints: PropTypes.array,
};
RouteSteps.defaultProps = {
  pathPoints: [],
};

export default RouteSteps;
