import styled from "styled-components";
import { getRem } from "../../../utils/css";

const SingleBar = styled.div`
  flex-grow: 1;
  height: ${(props) => (props.small ? getRem(4) : getRem(8))};
  & {
    min-height: ${(props) => (props.small ? getRem(4) : getRem(8))};
  }
  background-color: ${(props) =>
    props.completed
      ? props.delayed
        ? props.theme.colors.dhlRed
        : props.theme.colors.brightGreen
      : "#e8e8e8"};
  border-right: ${getRem(2)} solid white;
  &:last-child {
    border-right: ${getRem(2)} solid transparent;
  }
`;

export default SingleBar;
