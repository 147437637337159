import styled from "styled-components";
import { getRem, getRemMedia } from "../../utils/css";

export const PrintLayoutWrapper = styled.div`
  margin-top: ${getRem(-64)};
  @media (min-width: ${getRemMedia(1024.2)}) {
    margin-left: ${getRem(-64)};
  }
  padding: ${getRem(40)};
  @media print {
    padding: 0;
  }
`;

export const GoBackWrapper = styled.span`
  font-size: ${getRem(14)};
  line-height: 1;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.black60};
  cursor: pointer;
  span {
    font-size: ${getRem(20)};
  }
  @media print {
    display: none;
  }
`;
