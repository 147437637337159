import { flyOutListSearchWrapperStyles } from "components/molecules/FlyOut/styles";
import { css } from "styled-components";
import { getRem } from "utils/css";

export const scopeSelectorWrapperStyles = css`
  ${flyOutListSearchWrapperStyles};
  box-shadow: 0 ${getRem(1)} ${getRem(2)} 0 rgba(0, 0, 0, 0.1),
    0 ${getRem(8)} ${getRem(18)} 0 rgba(0, 0, 0, 0.1),
    0 ${getRem(2)} ${getRem(6)} ${getRem(-1)} rgba(0, 0, 0, 0.1);
  background-color: ${(p) => p.theme.colors.white};
`;
