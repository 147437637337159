import { BookingsListColumn } from "components/organisms/BookingsList/BookingsListHeader/BookingsListHeader.constants";
import { SORT_DIRECTIONS } from "redux/sort/constants";
import { transformDateForExport } from "../Bookings.helpers";

export const bookingsTabColumns: BookingsListColumn[] = [
  {
    dataName: "followed",
    translationKey: "Follow",
    uniqueValueColumnName: "FOLLOWED",
    sortable: false,
    customSize: 3.2,
    removable: false,
    visible: true,
    isDropdown: true,
    multiValue: true,
  },
  {
    dataName: "product",
    translationKey: "Mode",
    uniqueValueColumnName: "PRODUCT",
    sortable: true,
    customSize: 2.2,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "serviceLevel",
    translationKey: "Service",
    uniqueValueColumnName: "SERVICE_LEVEL",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "housebill",
    translationKey: "Housebill",
    uniqueValueColumnName: "HOUSEBILL",
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "quoteId",
    translationKey: "QuoteID",
    uniqueValueColumnName: "QUOTE_ID",
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "statusUser",
    translationKey: "BookingStatusUser",
    uniqueValueColumnName: "STATUS_USER",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "originCountry",
    translationKey: "OriginCountry",
    uniqueValueColumnName: "ORIGIN_COUNTRY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "destinationCountry",
    translationKey: "DestinationCountry",
    uniqueValueColumnName: "DESTINATION_COUNTRY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "billingCountry",
    translationKey: "BillingCountry",
    uniqueValueColumnName: "BILLING_COUNTRY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "originCity",
    translationKey: "OriginCity",
    uniqueValueColumnName: "ORIGIN_CITY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "destinationCity",
    translationKey: "DestinationCity",
    uniqueValueColumnName: "DESTINATION_CITY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "shipperCompany",
    translationKey: "ShipperCompany",
    uniqueValueColumnName: "SHIPPER_COMPANY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "consigneeCompany",
    translationKey: "ConsigneeCompany",
    uniqueValueColumnName: "CONSIGNEE_COMPANY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "billingCompany",
    translationKey: "BillingCompany",
    uniqueValueColumnName: "BILLING_COMPANY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "dateCreated",
    translationKey: "CreationDate",
    uniqueValueColumnName: "DATE_CREATED",
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
    hasDateType: true,
    transformFilterForExport: transformDateForExport("created"),
  },
  {
    dataName: "dateSent",
    translationKey: "SentDate",
    uniqueValueColumnName: "DATE_SENT",
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
    hasDateType: true,
    transformFilterForExport: transformDateForExport("sent"),
  },
  {
    dataName: "dateSubmitted",
    translationKey: "SubmittedDate",
    uniqueValueColumnName: "DATE_SUBMITTED",
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
    hasDateType: true,
    transformFilterForExport: transformDateForExport("submitted"),
  },
  {
    dataName: "userEmail",
    translationKey: "CreatedBy",
    uniqueValueColumnName: "USER_EMAIL",
    sortable: true,
    customSize: 12,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "reference",
    translationKey: "References",
    uniqueValueColumnName: "REFERENCE",
    isFilteringDisabled: false,
    sortable: false,
    customSize: 8,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "bookingName",
    translationKey: "BookingName",
    uniqueValueColumnName: "BOOKING_NAME",
    sortable: true,
    customSize: 28,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "totalWeight",
    translationKey: "Weight",
    uniqueValueColumnName: "TOTAL_WEIGHT",
    isFilteringDisabled: true,
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "totalVolume",
    translationKey: "Volume",
    uniqueValueColumnName: "TOTAL_VOLUME",
    isFilteringDisabled: true,
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "ratesType",
    translationKey: "RatesType",
    uniqueValueColumnName: "RATES_TYPE",
    isFilteringDisabled: false,
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "quoteTotal",
    translationKey: "QuoteTotal",
    uniqueValueColumnName: "QUOTE_TOTAL",
    isFilteringDisabled: true,
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "serviceTotal",
    translationKey: "ServiceTotal",
    uniqueValueColumnName: "SERVICE_TOTAL",
    isFilteringDisabled: true,
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "exportCustomsClearanceValue",
    translationKey: "ExportCustomsClearanceValue",
    uniqueValueColumnName: "EXPORT_CUSTOMS_CLEARANCE",
    isFilteringDisabled: true,
    sortable: false,
    customSize: 13,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "importCustomsClearanceValue",
    translationKey: "ImportCustomsClearanceValue",
    uniqueValueColumnName: "IMPORT_CUSTOMS_CLEARANCE",
    isFilteringDisabled: true,
    sortable: false,
    customSize: 13,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "insuranceValue",
    translationKey: "InsuranceValue",
    uniqueValueColumnName: "INSURANCE",
    isFilteringDisabled: true,
    sortable: false,
    customSize: 13,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "sustainabilityOfferingValue",
    translationKey: "SustainabilityOfferingValue",
    uniqueValueColumnName: "SUSTAINABILITY_OFFERING",
    isFilteringDisabled: true,
    sortable: false,
    customSize: 13,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
];
