import styled from "styled-components";
import { getRem } from "../../utils/css";

const DataLabel = styled.span`
  font-weight: 300;
  display: inline-block;
  padding-right: ${getRem(10)};
`;

export default DataLabel;
